import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/gatsby"
import { Row, Col } from "reactstrap"
import { Card, CardHeader, CardContent } from "@material-ui/core"
import EchartsBar from "../../../metrics-echarts/echarts-bar"
import { fetch_get } from "../../../../../utils/fetch"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../../../../../components/loading"
import DatePicker from "react-datepicker"
import moment from "moment"
import { toast } from "react-toastify"

export default function MetricsProjectDetail (props) {
  const { selectFile, repokey, urlparams } = props
  const { getAccessTokenSilently } = useAuth0()
  const [topIps, setTopIps] = useState([])
  const [topCountry, setTopCountry] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const defalutStartDate = moment().subtract(7, "days")
  const [startDate, setStartDate] = useState(new Date(defalutStartDate))
  const [endDate, setEndDate] = useState(new Date())
  const [submitFlag, setSubmitFlag]  = useState(null)
  useEffect(() => {
    if (selectFile.uri) {
      setLoading(true)
      setError(false)
      let query = ""
      if (startDate) {
        query = query + "&startDate=" + moment(startDate).format("yyyy-MM-DD")
      }
      if (endDate) {
        query = query + "&endDate=" + moment(endDate).format("yyyy-MM-DD")
      }
      const repo_topips = getAccessTokenSilently()
        .then(
          accessToken =>
          fetch_get(
            `metrics/${props.projectId}/artifactory/file${selectFile.path}/topIps${urlparams}&topN=10${query}&repoKey=${repokey}`,
            accessToken
          )
        )
        .then(response => {
          setTopIps(
            response.map(res => ({
              name: res.ip,
              value: res.download,
            }))
          )
        })
        .catch(error => {
          Sentry.captureException(error)
          console.log(`Error while getting file download top ips: ${error}`)
          toast(`${error}`, {
            type: "error",
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          setError(true)
          setLoading(false)
        })
      const repo_topcountry = getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/${props.projectId}/artifactory/file${selectFile.path}/topCountries${urlparams}&topN=10${query}&repoKey=${repokey}`,
            accessToken
          )
        )
        .then(response => {
          setTopCountry(
            response.map(res => ({
              name: res.country_name,
              value: res.download,
            }))
          )
        })
        .catch(error => {
          Sentry.captureException(error)
          console.log(`Error while getting file download top countries: ${error}`)
          toast(`${error}`, {
            type: "error",
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          setError(true)
          setLoading(false)
        })
      Promise.all([repo_topips, repo_topcountry]).then(() => {
        setLoading(false)
      })
    }
  }, [selectFile, submitFlag]) // eslint-disable-line react-hooks/exhaustive-deps

  const handle_submit =() => {
    setSubmitFlag(Date.now())
  }

  return (
    <div>
      <Row
        className="mt-2 mb-2 mr-0 ml-0"
        style={{ "border-bottom": "1px solid #ebebeb", padding: "1rem", margin: "0 0 10px" }}
      >
        <Col xs={{ size: 5 }}>
          <span className="mr-2">Start Date:</span>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate ? endDate : new Date()}
            dateFormat="yyyy-MM-dd"
            // isClearable
          />
        </Col>
        <Col xs={{ size: 5 }}>
          <span className="mr-2">End Date:</span>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate ? startDate : null}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd"
            // isClearable
          />
        </Col>
        <Col xs={{ size: 2 }}>
          <button
              type="button"
              style={{position: "absolute", bottom: 0}}
              className="btn btn-primary btn-sm"
              onClick={() => handle_submit()}
            >
              <strong>Go</strong>
          </button>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : error ? (
        <span>
          An error has occurred while trying to retrieve the information for artifacts file. If this
          continues, please raise a support ticket.
        </span>
      ) : (
        <>
          <Row>
            <Col>
              <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                <CardHeader title="Download Statistics by Country"></CardHeader>
                <CardContent style={{ width: "100%", height: 260 }}>
                  <EchartsBar barId="country" barData={topCountry} title="Country"></EchartsBar>
                </CardContent>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                <CardHeader title="Download Statistics by IP"></CardHeader>
                <CardContent style={{ width: "100%", height: 260 }}>
                  <EchartsBar barId="ip" barData={topIps} title="IP"></EchartsBar>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
