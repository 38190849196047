import React, { useState, useEffect } from "react"
import { Row, Col, Table, Tooltip } from "reactstrap"
import { fetch_get } from "../../../../utils/fetch"
import { PropagateLoader } from "react-spinners"
import { useAuth0 } from "@auth0/auth0-react"
import * as Sentry from "@sentry/gatsby"
import { css } from "glamor"
import "../../../../styles/metrics.scss"
import { useUserOrgs } from "../../../../hooks/use-user"

export default function MetricsProjectDetail(props) {
  const { getAccessTokenSilently } = useAuth0()
  const [tableLoading, setTableLoading] = useState(true)
  const [tableError, setTableError] = useState(false)
  const [tableData, setTableData] = useState([])
  const {
    currentOrg: {
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  useEffect(() => {
    if (isSuperAdmin === undefined || isSuperAdmin === null) {
      return
    }
    window.history.pushState(
      "",
      "",
      window.location.origin + window.location.pathname + "?t=wiki"
    )
    setTableData([])
    getProjectRepos().catch(error => {
      setTableLoading(false)
      setTableError("An error occurred while getting the list of wiki page.")
      console.log(`Error while getting workgroup wiki page: ${error}`)
      Sentry.captureException(error)
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId, getParentOrganization(), isSuperAdmin])

  function getParentOrganization() {
    return props.parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (isSuperAdmin) {
      urlparams = `?parentOrganization=${getParentOrganization()}`
    }
    return urlparams
  }

  const getProjectRepos = () => {
    var urlparams = getAdditionalUrlParams()

    let url = `projects/${props.projectId}/wiki/pages${urlparams}`
    return getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setTableLoading(false)
        setTableError(false)
        setTableData(response || [])
      })
  }

  function UseToolTip(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const { item, show } = props
    const text = item[show].length > 32 ? item[show].substring(0, 32) + "..." : item[show]
    return (
      <>
        <span id={`wiki-${show}-${item.id}`}>
          {item[show].startsWith("https://") || item[show].startsWith("http://") ? (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <a
                className="linkJump handlePointer"
                {...css(styles.link)}
                onClick={() => {
                  window.open(`${item[show]}`, "_blank", "noreferrer")
                }}
              >
                {text}
              </a>
            </>
          ) : (
            text
          )}
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={`wiki-${show}-${item.id}`}
          toggle={() => setTooltipOpen(!tooltipOpen)}
        >
          {item[show]}
        </Tooltip>
      </>
    )
  }

  return (
    <>
      {tableLoading && (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          {...css(styles.loader)}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      )}
      <div style={{ opacity: tableLoading ? ".5" : "1" }}>
        {!tableLoading && tableError ? (
          <p {...css(styles.noData)}>{tableError}</p>
        ) : (
          <>
            {tableData.length > 0 ? (
              <div id="wikiWrapper">
                <Row>
                  <Col>
                    <div className="table-border">
                      <Table>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Path</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td width="20%">{item.title}</td>
                                <td width="30%">
                                  <UseToolTip item={item} show="fullPath" />
                                </td>
                                <td width="50%">
                                  <UseToolTip item={item} show="description" />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              !tableLoading && <p>There are no wiki page.</p>
            )}
          </>
        )}
      </div>
    </>
  )
}
const styles = {}
