import React, { useState, useEffect } from "react"
import { Row, Col, Table } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import "../../../../../styles/metrics.scss"
import _ from "lodash"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_get } from "../../../../../utils/fetch"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"

export default function MetricsProjecCIPipelineTabular(props) {
  const [loadingStatus, setLoadingStatus] = useState({
    loading: false,
    error: false,
    errorMessage: null,
  })
  const [summeryData, setSummeryData] = useState(null)
  const { getAccessTokenSilently } = useAuth0()
  const getSummaryData = () => {
    setLoadingStatus({ loading: true, error: false, errorMessage: null })
    let queryParams = `?startDate=${props.startDate}&endDate=${props.endDate}&parentOrganization=${
      props.parentOrg
    }${_.isNil(props.orgIndex) ? "" : `&index=${props.orgIndex}`}`

    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`metrics/projects/${props.projectSlug}/ci/pipelines${queryParams}`, accessToken)
      )
      .then(response => {
        setSummeryData(response)
        setLoadingStatus({ loading: false, error: false, errorMessage: null })
      })
      .catch(error => {
        setLoadingStatus({ loading: true, error: true, errorMessage: error.message })
      })
  }

  useEffect(() => {
    getSummaryData()
  }, [props.counter]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loadingStatus.loading && !loadingStatus.error && (
        <>
          <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading Repos Info..
        </>
      )}
      {!loadingStatus.loading && !loadingStatus.error && summeryData !== null && (
        <>
          <Row>
            <Col>
              {summeryData.length > 0 && (
                <>
                  <div className="table-border">
                    <Table>
                      <thead>
                        <tr>
                          <th>Pipeline ID</th>
                          <th>Status</th>
                          <th>Repo Name</th>
                          <th>Cost</th>
                          <th>Triggered by</th>
                        </tr>
                      </thead>
                      <tbody>
                        {summeryData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.pipeline_id}</td>
                              <td>
                                {item.pipeline_status === "failed" && (
                                  <>
                                    <FaThumbsDown style={{ fill: "red" }} />
                                    &nbsp;
                                  </>
                                )}
                                {item.pipeline_status === "success" && (
                                  <>
                                    <FaThumbsUp style={{ fill: "green" }} />
                                    &nbsp;
                                  </>
                                )}
                                {_.startCase(item.pipeline_status)}
                              </td>
                              <td>
                                <a target="_blank" href={`${item.repo_path}`}>
                                  {item.repo_name}
                                </a>
                              </td>
                              <td>
                                $<strong>{new Number(item.cost).toFixed(2)}</strong>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href={`/metrics/users/${item.trigger_by_user_name}/`}
                                >
                                  {item.trigger_by_display_name}
                                </a>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
              {summeryData.length === 0 && <>Sorry! No Repos found</>}
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
