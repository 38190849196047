import React, { useState, useEffect } from "react"
import { Row, Col, Input } from "reactstrap"
import { fetch_get } from "../../../../utils/fetch"
import { PropagateLoader } from "react-spinners"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import { Card, CardHeader, CardContent } from "@material-ui/core"
import EchartsPie from "../../metrics-echarts/echarts-pie"
import EchartsBar from "../../metrics-echarts/echarts-bar"
import "../../../../styles/metrics.scss"
import moment from "moment"
import DatePicker from "react-datepicker"
import ProjectsByIP from "./projectmetricsgraph/projectsByIP"
import {
  project_metrics_get_start_date,
  project_metrics_get_end_date,
  getDateDiffInSeconds,
  sleep,
} from "../../../../utils/common"
import { FaRedoAlt } from "react-icons/fa"
import { Loading } from "../../../../components/loading"

export default function MetricsProjectDetail(props) {
  const { orgIndex, hasArtifas, hasGit, queryParams } = props
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [init, setInit] = useState(true)
  const [repoData, setRepoData] = useState([])
  const [mirrosData, setMirrosData] = useState([])
  const [repoTotal, setRepoTotal] = useState([])
  const [mirrosTotal, setMirrosTotal] = useState([])
  const [topUsers, setTopUsers] = useState([])
  const [topIps, setTopIps] = useState([])
  const [topCountries, setTopCountries] = useState([])
  const [mainSubmitClicked, setMainSubmitClicked] = useState(false)

  const [gitTopUsers, setGitTopUsers] = useState([])
  const [gitTopIps, setGitTopIps] = useState([])
  const [gitPushTopCountries, setGitPushTopCountries] = useState([])
  const [gitPullTopCountries, setGitPullTopCountries] = useState([])
  const [gitTopPullUsers, setGitTopPullUsers] = useState([])
  const [gitPullTopIp, setGitPullTopIp] = useState([])

  const [artifactoryRepos, setArtifactoryRepos] = useState([])
  const [usersRepokey, setUsersRepokey] = useState(null)
  const [ipsRepokey, setIpsRepokey] = useState(null)
  const [countryRepokey, setCountryRepokey] = useState(null)

  const [usersRepokeyLoading, setUsersRepokeyLoading] = useState(true)
  const [ipsRepokeyLoading, setIpsRepokeyLoading] = useState(true)
  const [countryRepokeyLoading, setCountryRepokeyLoading] = useState(true)

  const [startDate, setStartDate] = useState(project_metrics_get_start_date(7))
  const [endDate, setEndDate] = useState(project_metrics_get_end_date())
  const [submitFlag, setSubmitFlag] = useState(null)

  const [projectStatisticsLoader, setProjectStatisticsLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  const [projectTopPushIpsLoader, setProjectTopPushIpsLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  const [projectTopPushUsersLoader, setProjectTopPushUsersLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  const [projectTopPushCountriesLoader, setProjectTopPushCountriesLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  const [projectTopPullCountriesLoader, setProjectTopPullCountriesLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  const [projectTopPullUsersLoader, setProjectTopPullUsersLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  const [projectTopPullIpsLoader, setProjectTopPullIpsLoader] = useState({
    loading: false,
    errorMessage: null,
  })
  const [projectArtifactoryDataLoader, setProjectArtifactoryDataLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  const [projectArtifactoryUserLoader, setProjectArtifactoryUserLoader] = useState({
    loading: false,
    errorMessage: null,
  })
  const [projectArtifactoryCountryLoader, setProjectArtifactoryCountryLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  const [projectArtifactoryIpsLoader, setProjectArtifactoryIpsLoader] = useState({
    loading: false,
    errorMessage: null,
  })

  var failed_api_calls = []

  var [failed_api_calls, set_failed_api_calls] = useState([])

  // const [projectTopPushCountriesFlashMessage, setProjectTopPushCountriesFlashMessage] = useState({
  //   stdate: null,
  //   message: null,
  // })

  // const [projectTopPushUsersFlashMessage, setProjectTopPushUsersFlashMessage] = useState({
  //   stdate: null,
  //   message: null,
  //   counter: 0,
  // })

  // // useEffect(() => {
  // //   if (projectTopPushCountriesFlashMessage.stdate === null) return
  // //   const date1 = projectTopPushCountriesFlashMessage.stdate
  // //   const date2 = moment()

  // //   const diffInSeconds = date2.diff(date1, "seconds")
  // //   console.log(diffInSeconds, date1, date2)
  // //   if (diffInSeconds > 5) {
  // //     setTimeout(() => {
  // //       setProjectTopPushCountriesFlashMessage({
  // //         stdate: date2,
  // //         message: "This is taking longer than expected. Please be patient",
  // //       })
  // //     }, 1000)
  // //   }
  // // }, [projectTopPushCountriesFlashMessage.stdate])

  // useEffect(() => {
  //   console.log(projectTopPushUsersFlashMessage)
  //   if (projectTopPushUsersFlashMessage.stdate === null) return
  //   if (projectTopPushUsersFlashMessage.counter === 0) return

  //   async function fetchData() {
  //     await sleep(1000)
  //     const date1 = projectTopPushUsersFlashMessage.stdate
  //     const date2 = new Date()
  //     // console.log(date1, date2)
  //     const diffInSeconds = getDateDiffInSeconds(date2, date1)
  //     console.log(diffInSeconds, date1, date2)
  //     var msg = null
  //     if (diffInSeconds > 5) msg = "This is taking longer than expected. Please be patient"
  //     setProjectTopPushUsersFlashMessage({
  //       stdate: msg === null ? projectTopPushUsersFlashMessage.stdate : null,
  //       message: msg,
  //       counter: msg === null ? projectTopPushUsersFlashMessage.counter + 1 : 0,
  //     })
  //   }
  //   fetchData()
  // }, [projectTopPushUsersFlashMessage.counter])

  function getParentOrganization() {
    return props.parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (props.isSuperAdmin) {
      urlparams = `?parentOrganization=${getParentOrganization()}`
    } else {
      urlparams = `?index=${orgIndex}`
    }
    return urlparams
  }

  const load_project_details_statistics = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_details_statistics
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectStatisticsLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/single${urlparams}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          const repoKey = Object.keys(response.data.repo)
          const mirrorKey = Object.keys(response.data.mirror)
          const repoArr = repoKey.map(item => {
            return {
              value: response.data.repo[item],
              name: _.upperFirst(item),
            }
          })
          const mirrorArr = mirrorKey.map(item => {
            return {
              value: response.data.mirror[item],
              name: _.upperFirst(item),
            }
          })
          setRepoData(repoArr.filter(item => item?.name !== "Total"))
          setMirrosData(mirrorArr.filter(item => item?.name !== "Total"))
          setRepoTotal(response.data.repo.total)
          setMirrosTotal(response.data.mirror.total)
          setProjectStatisticsLoader({ loading: false, errorMessage: null })
          resolve()
        })
        .catch(error => {
          setProjectStatisticsLoader({
            loading: false,
            errorMessage: "failed to load project statistics",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_top_push_ips = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_top_push_ips
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectTopPushIpsLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/git/topIps?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setGitTopIps(
            response.map(res => ({
              name: res.ip,
              value: res.pushCount,
              orgName: res.organizationName || "-",
            }))
          )
          setProjectTopPushIpsLoader({ loading: false, errorMessage: null })

          resolve()
        })
        .catch(error => {
          setProjectTopPushIpsLoader({
            loading: false,
            errorMessage: "Failed to load project push ips",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_top_push_users = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_top_push_users
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      // setProjectTopPushUsersFlashMessage({ stdate: new Date(), message: null, counter: 1 })
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectTopPushUsersLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/git/topUsers?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setGitTopUsers(
            response.map(res => ({
              name: res.username,
              value: res.pushCount,
            }))
          )
          setProjectTopPushUsersLoader({ loading: false, errorMessage: null })

          resolve()
        })
        .catch(error => {
          setProjectTopPushUsersLoader({
            loading: false,
            errorMessage: "failed to load project push users",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          // setProjectTopPushUsersFlashMessage({ stdate: null, message: null, counter: 0 })
          // console.log("set date to null")
          resolve()
        })
    })
  }

  const load_project_top_push_countries = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_top_push_countries
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      // setProjectTopPushCountriesFlashMessage({ stdate: moment(), message: null })
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectTopPushCountriesLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/git/topCountries?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setGitPushTopCountries(
            response.map(res => ({
              name: res.country_name,
              value: res.pushCount,
            }))
          )
          setProjectTopPushCountriesLoader({ loading: false, errorMessage: null })
          // setProjectTopPushCountriesFlashMessage({ stdate: null, message: null })
          resolve()
        })
        .catch(error => {
          setProjectTopPushCountriesLoader({
            loading: false,
            errorMessage: "failed to load project push countries",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_top_pull_countries = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_top_pull_countries
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectTopPullCountriesLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/git/pull/topCountries?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setGitPullTopCountries(
            response.map(res => ({
              name: res.country_name,
              value: res.pullCount,
            }))
          )
          setProjectTopPullCountriesLoader({ loading: false, errorMessage: null })
          // setProjectTopPullCountriesLoader({
          //   loading: false,
          //   errorMessage: "This is taking longer than expected. Please be patient",
          // })
          // set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
        .catch(error => {
          setProjectTopPullCountriesLoader({
            loading: false,
            errorMessage: "failed to load pull countries",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_top_pull_users = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_top_pull_users
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectTopPullUsersLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/git/pull/topPullUsers?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setGitTopPullUsers(
            response.map(res => ({
              name: res.username,
              value: res.pullCount,
            }))
          )
          setProjectTopPullUsersLoader({ loading: false, errorMessage: null })
          resolve()
        })
        .catch(error => {
          setProjectTopPullUsersLoader({
            loading: true,
            errorMessage: "failed to load pull users",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_top_pull_ips = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_top_pull_ips
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectTopPullIpsLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/git/pull/topIps?forgraph=1&topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setGitPullTopIp(
            response.map(res => ({
              name: res.ip,
              value: res.pullCount,
              orgName: res.organizationName || "-",
            }))
          )
          setProjectTopPullIpsLoader({ loading: false, errorMessage: null })
          resolve()
        })
        .catch(error => {
          setProjectTopPullIpsLoader({
            loading: false,
            errorMessage: "failed to load pull ips",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_artifactory_data = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_artifactory_data
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectArtifactoryDataLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `projects/${props.projectId}/artifactory/repos${urlparams}&all=true`,
            accessToken
          )
        )
        .then(response => {
          const data = [...(response.data || [])].map(r => ({
            repoKey: r.artifactRepoID,
            repoName: r.repoName,
          }))
          setArtifactoryRepos(data)
          setUsersRepokey(data[0] ? data[0].repoName : null)
          setIpsRepokey(data[0] ? data[0].repoName : null)
          setCountryRepokey(data[0] ? data[0].repoName : null)
          setProjectArtifactoryDataLoader({ loading: false, errorMessage: null })
          resolve()
        })
        .catch(error => {
          setProjectArtifactoryDataLoader({
            loading: false,
            errorMessage: "failed to load artifactory data",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_artifactory_users = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_artifactory_users
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectArtifactoryUserLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/artifactory/topUsers?repoKey=${usersRepokey}&topN=5${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setTopUsers(
            response.map(item => ({
              value: item.download,
              name: item.user,
            }))
          )
          setProjectArtifactoryUserLoader({ loading: false, errorMessage: null })
          resolve()
        })
        .catch(error => {
          setProjectArtifactoryUserLoader({
            loading: true,
            errorMessage: "failed to load artifactory user",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_artifactory_country = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_artifactory_country
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectArtifactoryCountryLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/artifactory/topCountries?repoKey=${countryRepokey}&topN=5${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setTopCountries(
            response.map(item => ({
              value: item.download,
              name: item.country_name,
            }))
          )
          setProjectArtifactoryCountryLoader({ loading: false, errorMessage: null })
          resolve()
        })
        .catch(error => {
          setProjectArtifactoryCountryLoader({
            loading: false,
            errorMessage: "failed to load artifactory country",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_project_artifactory_ips = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_artifactory_ips
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      var urlparams = getAdditionalUrlParams()
      var urlparamsAmp = urlparams.replace("?", "&")
      const start = moment(startDate).format("yyyy-MM-DD")
      const end = moment(endDate).format("yyyy-MM-DD")
      setProjectArtifactoryIpsLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `metrics/projects/${props.projectId}/artifactory/topIps?repoKey=${ipsRepokey}&topN=5${urlparamsAmp}&startDate=${start}&endDate=${end}`,
            accessToken
          )
        )
        .then(response => {
          setTopIps(
            response.map(item => ({
              value: item.download,
              name: item.ip,
              orgName: item.organizationName,
            }))
          )
          setProjectArtifactoryIpsLoader({ loading: false, errorMessage: null })
          resolve()
        })
        .catch(error => {
          setProjectArtifactoryIpsLoader({
            loading: false,
            errorMessage: "failed to get artifactory IP",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          resolve()
        })
    })
  }

  const load_all_cards = async () => {
    let urlQuery = `?t=stats`

    const start = moment(startDate).format("yyyy-MM-DD")
    const end = moment(endDate).format("yyyy-MM-DD")

    if (start) urlQuery += `&startDate=${start}`
    if (end) urlQuery += `&endDate=${end}`
    window.history.pushState("", "", window.location.origin + window.location.pathname + urlQuery)
    setMainSubmitClicked(true)
    // await load_repo_push_pull_details()
    // await load_unique_users()
    // await load_unique_ips()
    if (hasGit) {
      await load_project_details_statistics()
      await load_project_top_push_users()
      await load_project_top_push_ips()
      await load_project_top_push_countries()
      await load_project_top_pull_countries()
      await load_project_top_pull_users()
      await load_project_top_pull_ips()
    }
    if (hasArtifas && init) {
      setIpsRepokey(null)
      setUsersRepokey(null)
      setCountryRepokey(null)
      await load_project_artifactory_data()
      // if (usersRepokey) await load_project_artifactory_users()
      // if (ipsRepokey) await load_project_artifactory_ips()
      // if (countryRepokey) await load_project_artifactory_country()
    }

    // var copy_failed_api_calls = [...failed_api_calls]
    // console.log(copy_failed_api_calls)
    // for await (const method of copy_failed_api_calls) {
    //   await method()
    // }

    setMainSubmitClicked(false)
  }

  const check_refresh_disabled = () => {
    return (
      projectStatisticsLoader.loading ||
      projectTopPullCountriesLoader.loading ||
      projectTopPullIpsLoader.loading ||
      projectTopPullUsersLoader.loading ||
      projectTopPushCountriesLoader.loading ||
      projectTopPushIpsLoader.loading ||
      projectTopPushUsersLoader.loading ||
      projectArtifactoryCountryLoader.loading ||
      projectArtifactoryIpsLoader.loading ||
      projectArtifactoryUserLoader.loading
    )
  }

  useEffect(() => {
    async function fetchData() {
      await load_all_cards()
    }
    fetchData()
  }, [submitFlag])

  useEffect(() => {
    // if (mainSubmitClicked) return
    if (usersRepokey === null) return
    if (ipsRepokey === null) return
    if (countryRepokey === null) return
    async function fetchData() {
      await load_project_artifactory_users()
      await load_project_artifactory_ips()
      await load_project_artifactory_country()
    }
    fetchData()
  }, [usersRepokey, ipsRepokey, countryRepokey])

  useEffect(() => {
    if (failed_api_calls.length === 0) return
    console.log(failed_api_calls, "we are here")

    async function runfailedapicalls() {
      await sleep(10000)
      console.log("after 10 sec sleep")
      var ff = failed_api_calls[0]

      await ff()
    }
    runfailedapicalls()
  }, [failed_api_calls])

  const handle_submit = () => {
    setSubmitFlag(Date.now())
  }

  return (
    <>
      <Row className="mt-2 mb-4">
        <Col xs={{ size: 3 }}>
          <span className="mr-2">Start Date:</span>
          <br />
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate ? endDate : new Date()}
            dateFormat="yyyy-MM-dd"
          />
        </Col>
        <Col xs={{ size: 3 }}>
          <span className="mr-2">End Date:</span>
          <br />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd"
          />
        </Col>
        <Col xs={{ size: 1 }}>
          <span className="mr-2">&nbsp;</span>
          <br />
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handle_submit()}
            disabled={check_refresh_disabled()}
          >
            <strong>Go</strong>
          </button>
        </Col>
      </Row>
      {/* {loading ? (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          sm={{ size: 4, offset: 4 }}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      ) : ( */}
      <>
        {hasGit ? (
          <>
            <Row>
              <Col xm={6} sm={6} md={6}>
                <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                  <CardHeader title="projects Detail Statistics by Repo"></CardHeader>
                  {projectStatisticsLoader.loading && (
                    <>
                      <Loading />
                    </>
                  )}

                  {!projectStatisticsLoader.loading &&
                    projectStatisticsLoader.errorMessage !== null && (
                      <>
                        <div className="alert alert-danger" role="alert">
                          {/* Oops ! Error occured. */}
                          {projectStatisticsLoader.delayMessage}
                        </div>
                      </>
                    )}

                  {!projectStatisticsLoader.loading &&
                    projectStatisticsLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 260 }}>
                          <EchartsPie
                            pieId="repo"
                            pieData={repoData}
                            total={repoTotal}
                            title="Repo"
                          ></EchartsPie>
                        </CardContent>
                      </>
                    )}
                </Card>
              </Col>
              <Col xm={6} sm={6} md={6}>
                <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                  <CardHeader title="projects Detail Statistics by Mirrors"></CardHeader>
                  {projectStatisticsLoader.loading && (
                    <>
                      <Loading />
                    </>
                  )}

                  {!projectStatisticsLoader.loading &&
                    projectStatisticsLoader.errorMessage !== null && (
                      <>
                        <div className="alert alert-danger" role="alert">
                          {/* Oops ! Error occured. */}
                          {projectStatisticsLoader.delayMessage}
                        </div>
                      </>
                    )}

                  {!projectStatisticsLoader.loading &&
                    projectStatisticsLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 260 }}>
                          <EchartsPie
                            pieId="mirrors"
                            pieData={mirrosData}
                            total={mirrosTotal}
                            title="Mirrors"
                          ></EchartsPie>
                        </CardContent>
                      </>
                    )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                  <CardHeader title="Project Push Statistics by User"></CardHeader>
                  {/* {projectTopPushUsersFlashMessage.message} */}
                  {projectTopPushUsersLoader.loading && (
                    <>
                      <Loading />
                    </>
                  )}

                  {!projectTopPushUsersLoader.loading &&
                    projectTopPushUsersLoader.errorMessage !== null && (
                      <>
                        <div className="alert alert-danger" role="alert">
                          {projectTopPushUsersLoader.delayMessage}
                          {/* Oops ! Error occured.{" "}
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => load_project_top_push_users()}
                          >
                            <strong>try again</strong>
                          </button> */}
                        </div>
                      </>
                    )}

                  {!projectTopPushUsersLoader.loading &&
                    projectTopPushUsersLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 260 }}>
                          <EchartsBar
                            barId="gitTopUsers"
                            barData={gitTopUsers}
                            title="Project Push Top Users"
                          ></EchartsBar>
                        </CardContent>
                      </>
                    )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                  <CardHeader title="Project Push Statistics by IP"></CardHeader>
                  {projectTopPushIpsLoader.loading && (
                    <>
                      <Loading />
                    </>
                  )}

                  {!projectTopPushIpsLoader.loading &&
                    projectTopPushIpsLoader.errorMessage !== null && (
                      <>
                        <div className="alert alert-danger" role="alert">
                          {projectTopPushIpsLoader.delayMessage}
                          {/* Oops ! Error occured.{" "}
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => load_project_top_push_ips()}
                          >
                            <strong>try again</strong>
                          </button> */}
                        </div>
                      </>
                    )}

                  {!projectTopPushIpsLoader.loading &&
                    projectTopPushIpsLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 260 }}>
                          <EchartsBar
                            barId="gitTopIps"
                            barData={gitTopIps}
                            title="Push Count"
                            showOrgName={true}
                          ></EchartsBar>
                        </CardContent>
                      </>
                    )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                  <CardHeader title="Project Push Statistics by Country"></CardHeader>
                  {/* {projectTopPushCountriesFlashMessage.message} */}
                  {projectTopPushCountriesLoader.loading && (
                    <>
                      <Loading />
                    </>
                  )}

                  {!projectTopPushCountriesLoader.loading &&
                    projectTopPushCountriesLoader.errorMessage !== null && (
                      <>
                        <div className="alert alert-danger" role="alert">
                          {projectTopPushCountriesLoader.delayMessage}
                          {/* Oops ! Error occured.{" "} */}
                          {/* <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => load_project_top_push_countries()}
                          >
                            <strong>try again</strong>
                          </button> */}
                        </div>
                      </>
                    )}

                  {!projectTopPushCountriesLoader.loading &&
                    projectTopPushCountriesLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 260 }}>
                          <EchartsBar
                            barId="gitPushTopCountries"
                            barData={gitPushTopCountries}
                            title="Project Push Top Countries"
                          ></EchartsBar>
                        </CardContent>
                      </>
                    )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                  <CardHeader title="Project Pull Statistics by Country"></CardHeader>
                  {projectTopPullCountriesLoader.loading && (
                    <>
                      <Loading />
                    </>
                  )}

                  {!projectTopPullCountriesLoader.loading &&
                    projectTopPullCountriesLoader.errorMessage !== null && (
                      <>
                        <div className="alert alert-danger" role="alert">
                          {projectTopPullCountriesLoader.delayMessage}
                          {/* Oops ! Error occured.{" "}
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => load_project_top_pull_countries()}
                          >
                            <strong>try again</strong>
                          </button> */}
                        </div>
                      </>
                    )}

                  {!projectTopPullCountriesLoader.loading &&
                    projectTopPullCountriesLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 260 }}>
                          <EchartsBar
                            barId="gitPullTopCountries"
                            barData={gitPullTopCountries}
                            title="Project Pull Top Countries"
                          ></EchartsBar>
                        </CardContent>
                      </>
                    )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                  <CardHeader title="Project Pull Statistics by Users"></CardHeader>
                  {projectTopPullUsersLoader.loading && (
                    <>
                      <Loading />
                    </>
                  )}

                  {!projectTopPullUsersLoader.loading &&
                    projectTopPullUsersLoader.errorMessage !== null && (
                      <>
                        <div className="alert alert-danger" role="alert">
                          {projectTopPullUsersLoader.delayMessage}
                          {/* Oops ! Error occured.{" "}
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => load_project_top_pull_users()}
                          >
                            <strong>try again</strong>
                          </button> */}
                        </div>
                      </>
                    )}

                  {!projectTopPullUsersLoader.loading &&
                    projectTopPullUsersLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 260 }}>
                          <EchartsBar
                            barId="gitPullTopUsers"
                            barData={gitTopPullUsers}
                            title="Project Pull Top Users"
                          ></EchartsBar>
                        </CardContent>
                      </>
                    )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ width: "100%", height: 315, marginBottom: 20 }}>
                  <CardHeader title="Project Pull Statistics by IP"></CardHeader>
                  {projectTopPullIpsLoader.loading && (
                    <>
                      <Loading />
                    </>
                  )}

                  {!projectTopPullIpsLoader.loading &&
                    projectTopPullIpsLoader.errorMessage !== null && (
                      <>
                        <div className="alert alert-danger" role="alert">
                          {projectTopPullIpsLoader.delayMessage}
                          {/* Oops ! Error occured.{" "}
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => load_project_top_pull_ips()}
                          >
                            <strong>try again</strong>
                          </button> */}
                        </div>
                      </>
                    )}

                  {!projectTopPullIpsLoader.loading &&
                    projectTopPullIpsLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 260 }}>
                          <EchartsBar
                            barId="gitPullTopIp"
                            barData={gitPullTopIp}
                            title="Pull Count"
                            showOrgName={true}
                          ></EchartsBar>
                        </CardContent>
                      </>
                    )}
                </Card>
                {/* <Card style={{ width: "100%", marginBottom: 20 }}>
                    <CardHeader title="Top 10 projects by IP"></CardHeader>
                    <CardContent style={{ width: "100%" }}>
                      {gitPullTopIp !== null && gitPullTopIp.length > 0 && (
                        <>
                          <ProjectsByIP
                            iplist={gitPullTopIp}
                            parentOrganization={props.parentOrganization}
                            isSuperAdmin={props.isSuperAdmin}
                            projectSlug={props.projectId}
                            orgIndex={orgIndex}
                            startDate={startDate}
                            endDate={endDate}
                          />
                        </>
                      )}
                    </CardContent>
                  </Card> */}
              </Col>
            </Row>{" "}
          </>
        ) : null}
        {hasArtifas ? (
          <>
            <Row>
              <Col xm={6} sm={6} md={6}>
                <Card style={{ width: "100%", height: 355, marginBottom: 20 }}>
                  <CardHeader title="Artifacts Download Statistics by User"></CardHeader>
                  <CardContent style={{ width: "100%", height: 260 }}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <div class="mr-4">Repo ID: </div>
                      <div class="mr-4">
                        <Input
                          type="select"
                          name="usersRepokey"
                          id="usersRepokey"
                          value={usersRepokey}
                          onChange={event => setUsersRepokey(event.target.value)}
                        >
                          {artifactoryRepos.map(p => (
                            <option value={p.repoName} key={p.repoName}>
                              {p.repoName}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    {/* {usersRepokeyLoading ? (
                      <div
                        style={{ height: "100%" }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <PropagateLoader
                          className="align-self-center"
                          size={10}
                          color={"#9c3"}
                          loading={true}
                        />
                      </div>
                    ) : ( */}
                    {projectArtifactoryUserLoader.loading && (
                      <>
                        <Loading />
                      </>
                    )}

                    {!projectArtifactoryUserLoader.loading &&
                      projectArtifactoryUserLoader.errorMessage !== null && (
                        <>
                          <div className="alert alert-danger" role="alert">
                            {/* Oops ! Error occured. */}
                            {projectArtifactoryUserLoader.delayMessage}
                          </div>
                        </>
                      )}

                    {!projectArtifactoryUserLoader.loading &&
                      projectArtifactoryUserLoader.errorMessage === null && (
                        <>
                          <EchartsBar
                            barId="topUsers"
                            barData={topUsers}
                            title="Top Users"
                          ></EchartsBar>
                        </>
                      )}

                    {/* )} */}
                  </CardContent>
                </Card>
              </Col>
              <Col xm={6} sm={6} md={6}>
                <Card style={{ width: "100%", height: 355, marginBottom: 20 }}>
                  <CardHeader title="Artifacts Download Statistics by IP"></CardHeader>
                  <CardContent style={{ width: "100%", height: 260 }}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <div class="mr-4">Repo ID: </div>
                      <div class="mr-4">
                        <Input
                          type="select"
                          name="ipsRepokey"
                          id="ipsRepokey"
                          value={ipsRepokey}
                          onChange={event => setIpsRepokey(event.target.value)}
                        >
                          {artifactoryRepos.map(p => (
                            <option value={p.repoName} key={p.repoName}>
                              {p.repoName}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    {/* {ipsRepokeyLoading ? (
                      <div
                        style={{ height: "100%" }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <PropagateLoader
                          className="align-self-center"
                          size={10}
                          color={"#9c3"}
                          loading={true}
                        />
                      </div>
                    ) : ( */}
                    {projectArtifactoryIpsLoader.loading && (
                      <>
                        <Loading />
                      </>
                    )}

                    {!projectArtifactoryIpsLoader.loading &&
                      projectArtifactoryIpsLoader.errorMessage !== null && (
                        <>
                          <div className="alert alert-danger" role="alert">
                            {/* Oops ! Error occured. */}
                            {projectArtifactoryIpsLoader.delayMessage}
                          </div>
                        </>
                      )}

                    {!projectArtifactoryIpsLoader.loading &&
                      projectArtifactoryIpsLoader.errorMessage === null && (
                        <>
                          <EchartsBar
                            barId="topIps"
                            barData={topIps}
                            title="Top Ips"
                            showOrgName={true}
                          ></EchartsBar>
                        </>
                      )}

                    {/* )} */}
                  </CardContent>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xm={6} sm={6} md={6}>
                <Card style={{ width: "100%", height: 355, marginBottom: 20 }}>
                  <CardHeader title="Artifacts Download Statistics by Country"></CardHeader>
                  <CardContent style={{ width: "100%", height: 260 }}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <div class="mr-4">Repo ID: </div>
                      <div class="mr-4">
                        <Input
                          type="select"
                          name="countryRepokey"
                          id="countryRepokey"
                          value={countryRepokey}
                          onChange={event => setCountryRepokey(event.target.value)}
                        >
                          {artifactoryRepos.map(p => (
                            <option value={p.repoName} key={p.repoName}>
                              {p.repoName}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    {/* {countryRepokeyLoading ? (
                      <div
                        style={{ height: "100%" }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <PropagateLoader
                          className="align-self-center"
                          size={10}
                          color={"#9c3"}
                          loading={true}
                        />
                      </div>
                    ) : ( */}
                    {projectArtifactoryCountryLoader.loading && (
                      <>
                        <Loading />
                      </>
                    )}

                    {!projectArtifactoryCountryLoader.loading &&
                      projectArtifactoryCountryLoader.errorMessage !== null && (
                        <>
                          <div className="alert alert-danger" role="alert">
                            {/* Oops ! Error occured. */}
                            {projectArtifactoryCountryLoader.delayMessage}
                          </div>
                        </>
                      )}

                    {!projectArtifactoryCountryLoader.loading &&
                      projectArtifactoryCountryLoader.errorMessage === null && (
                        <>
                          <EchartsBar
                            barId="topCountries"
                            barData={topCountries}
                            title="Top Countries"
                          ></EchartsBar>
                        </>
                      )}

                    {/* )} */}
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </>
        ) : null}
      </>
      {/* // )} */}
    </>
  )
}
