import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  ButtonGroup,
  Tooltip,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import * as Sentry from "@sentry/gatsby"
import { MdClose } from "react-icons/md"
import { FaSearch } from "react-icons/fa"
import { BiSortDown, BiSortUp } from "react-icons/bi"
import { AiOutlineFilter } from "react-icons/ai"
import { fetch_get } from "../../../../utils/fetch"
import { useAuth0 } from "@auth0/auth0-react"
import { PropagateLoader } from "react-spinners"
import { css } from "glamor"
import Pagination from "@material-ui/lab/Pagination"
import _ from "lodash"
import {
  Tooltip as MateriaUiTooltip,
  makeStyles,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  FormControl,
  Popover,
  Typography,
  Checkbox,
  Box,
} from "@material-ui/core"
import { FaRegEye } from "react-icons/fa"
import moment from "moment"
import { useSearch } from "../../../../hooks/use-search"
import { useUserOrgs } from "../../../../hooks/use-user"
import { BsCheckCircle, BsAlarm, BsXCircle } from "react-icons/bs"
import { BiLoader } from "react-icons/bi"
import { RiAlarmWarningLine } from "react-icons/ri"

export default function Mirrors(props) {
  const { queryParams } = props
  const { getAccessTokenSilently } = useAuth0()
  const [mirrorsData, setMirrorsData] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(
    queryParams.get("page") ? parseInt(queryParams.get("page")) : 1
  )
  const [pageSize, setPageSize] = useState(
    queryParams.get("limit") ? parseInt(queryParams.get("limit")) : 10
  )
  const [quantity, setQuantity] = useState("0/0")
  const [status, setStatus] = useState(
    queryParams.get("status")
      ? queryParams.get("status").split(",")
      : ["pending", "in-progress", "mirrored", "inactive", "failed"]
  )
  const [statusCode, setStatusCode] = useState(queryParams.get("statusCode") || "all")
  const [selectMirror, setSelectMirror] = useState({})
  const [mirrorModal, setMirrorModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [contentError, setContentError] = useState(false)
  const [dateUpdatedSort, setDateUpdatedSort] = useState(queryParams.get("order") || "desc")
  const [frequency, setFrequency] = useState(
    queryParams.get("frequency")
      ? queryParams.get("frequency").split(",")
      : ["daily", "once", "weekly"]
  )
  const [frequencyAnchorEl, setFrequencyAnchorEl] = useState(null)
  const [statusAnchorEl, setStatusAnchorEl] = useState(null)

  const useStyles = makeStyles(() => ({
    modal: {
      width: 900,
    },
  }))
  const classes = useStyles()

  const {
    currentOrg: {
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  function getParentOrganization() {
    return props.parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (isSuperAdmin) urlparams = `?parentOrganization=${getParentOrganization()}`
    return urlparams
  }

  const callback = v => {
    setCurrentPage(1)
    setLoading(true)
    setContentError(false)
    const searchValue = v?.target?.value || ""
    getProjectMirrors(
      1,
      pageSize,
      status,
      statusCode,
      dateUpdatedSort,
      frequency,
      !!searchValue,
      searchValue
    ).catch(error => {
      setLoading(false)
      setContentError("An error occurred while getting the list of mirrors.")
      console.log(`Error while getting workgroup mirrors: ${error}`)
      Sentry.captureException(error)
    })
  }

  const { search, setSearch, callback: handleSearchValue } = useSearch(
    callback,
    null,
    queryParams.get("query") || ""
  )

  useEffect(() => {
    if (isSuperAdmin === undefined || isSuperAdmin === null) return
    const urlPage = queryParams.get("page") ? parseInt(queryParams.get("page")) : 1
    const urlLimit = queryParams.get("limit") ? parseInt(queryParams.get("limit")) : 10
    const urlStatus = queryParams.get("status")
      ? queryParams.get("status").split(",")
      : ["pending", "in-progress", "mirrored", "inactive", "failed"]
    const urlStatusCode = queryParams.get("statusCode") || "all"
    const urlDateUpdatedSort = queryParams.get("order") || "desc"
    const urlFrequency = queryParams.get("frequency")
      ? queryParams.get("frequency").split(",")
      : ["daily", "once", "weekly"]
    setCurrentPage(urlPage)
    setPageSize(urlLimit)
    setStatus(urlStatus)
    setStatusCode(urlStatusCode)
    setDateUpdatedSort(urlDateUpdatedSort)
    setFrequency(urlFrequency)
    setMirrorsData([])
    getProjectMirrors(
      urlPage,
      urlLimit,
      urlStatus,
      urlStatusCode,
      urlDateUpdatedSort,
      urlFrequency,
      true
    ).catch(error => {
      setLoading(false)
      setContentError("An error occurred while getting the list of mirrors.")
      console.log(`Error while getting workgroup mirrors: ${error}`)
      Sentry.captureException(error)
    })
  }, [getParentOrganization(), isSuperAdmin]) // eslint-disable-line react-hooks/exhaustive-deps

  function onPageChange(event, page) {
    setCurrentPage(page)
    setLoading(true)
    setContentError(false)
    getProjectMirrors(page, pageSize, status, statusCode, dateUpdatedSort, frequency, true).catch(
      error => {
        setLoading(false)
        setContentError("An error occurred while getting the list of mirrors.")
        console.log(`Error while getting workgroup mirrors: ${error}`)
        Sentry.captureException(error)
      }
    )
  }

  function onPageSizeChange(event) {
    const size = parseInt(event.target.value, 10)
    setPageSize(size)
    setCurrentPage(1)
    setLoading(true)
    setContentError(false)
    getProjectMirrors(1, size, status, statusCode, dateUpdatedSort, frequency, true).catch(
      error => {
        setLoading(false)
        setContentError("An error occurred while getting the list of mirrors.")
        console.log(`Error while getting workgroup mirrors: ${error}`)
        Sentry.captureException(error)
      }
    )
  }

  const handleStatus = (type, checked = "") => {
    let types = []
    if (checked === "") {
      types = type === "" ? [] : type.split(",")
    } else {
      if (checked) {
        types = [type, ...status]
      } else {
        types = status.filter(f => f !== type)
      }
    }
    setStatus(types)
    setCurrentPage(1)
    setLoading(true)
    setContentError(false)
    getProjectMirrors(1, pageSize, types, statusCode, dateUpdatedSort, frequency, true).catch(
      error => {
        setLoading(false)
        setContentError("An error occurred while getting the list of mirrors.")
        console.log(`Error while getting workgroup mirrors: ${error}`)
        Sentry.captureException(error)
      }
    )
  }

  const handleStatusCode = value => {
    setStatusCode(value)
    setCurrentPage(1)
    setLoading(true)
    setContentError(false)
    getProjectMirrors(1, pageSize, status, value, dateUpdatedSort, frequency, true).catch(error => {
      setLoading(false)
      setContentError("An error occurred while getting the list of mirrors.")
      console.log(`Error while getting workgroup mirrors: ${error}`)
      Sentry.captureException(error)
    })
  }

  const restartList = (isUseSearch = false) => {
    setCurrentPage(1)
    setLoading(true)
    setContentError(false)
    getProjectMirrors(
      1,
      pageSize,
      status,
      statusCode,
      dateUpdatedSort,
      frequency,
      isUseSearch
    ).catch(error => {
      setLoading(false)
      setContentError("An error occurred while getting the list of mirrors.")
      console.log(`Error while getting workgroup mirrors: ${error}`)
      Sentry.captureException(error)
    })
  }

  const handleSort = type => {
    if (type === dateUpdatedSort) return
    setDateUpdatedSort(type)
    setCurrentPage(1)
    setLoading(true)
    setContentError(false)
    getProjectMirrors(1, pageSize, status, statusCode, type, frequency, true).catch(error => {
      setLoading(false)
      setContentError("An error occurred while getting the list of mirrors.")
      console.log(`Error while getting workgroup mirrors: ${error}`)
      Sentry.captureException(error)
    })
  }

  const handleFrequency = (type, checked = "") => {
    let types = []
    if (checked === "") {
      types = type === "" ? [] : type.split(",")
    } else {
      if (checked) {
        types = [type, ...frequency]
      } else {
        types = frequency.filter(f => f !== type)
      }
    }
    setFrequency(types)
    setCurrentPage(1)
    setLoading(true)
    setContentError(false)
    getProjectMirrors(1, pageSize, status, statusCode, dateUpdatedSort, types, true).catch(
      error => {
        setLoading(false)
        setContentError("An error occurred while getting the list of mirrors.")
        console.log(`Error while getting workgroup mirrors: ${error}`)
        Sentry.captureException(error)
      }
    )
  }

  const getProjectMirrors = (
    page,
    limit,
    status,
    code,
    order,
    frequency,
    isUseSearch,
    searchValue
  ) => {
    var urlparams = getAdditionalUrlParams()
    urlparams = urlparams.replace("?", "&")

    let url = `projects/${props.projectId}/git/mirrors?page=${page}&limit=${limit}&order=${order}${urlparams}`
    let urlQuery = `?t=mirrors&page=${page}&limit=${limit}&order=${order}`
    if (status.length > 0) {
      url = url + `&status=${status.join(",")}`
      urlQuery += `&status=${status.join(",")}`
    }
    if (code !== "all") {
      url = url + `&statusCode=${code}`
      urlQuery += `&statusCode=${code}`
    }
    if (frequency.length > 0) {
      url = url + `&frequency=${frequency.join(",")}`
      urlQuery += `&frequency=${frequency.join(",")}`
    }
    if (isUseSearch) {
      if (searchValue && searchValue !== "") {
        url += `&query=${searchValue}`
        urlQuery += `&query=${searchValue}`
      } else {
        if (search !== "") {
          url += `&query=${search}`
          urlQuery += `&query=${search}`
        }
      }
    }
    window.history.pushState("", "", window.location.origin + window.location.pathname + urlQuery)
    return getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setLoading(false)
        setContentError(false)
        setMirrorsData(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
        setQuantity(response["pagination"]["quantity"] || "0/0")
      })
  }

  const showMirrorModal = data => {
    setSelectMirror(data)
    setMirrorModal(!mirrorModal)
  }

  const handleReset = () => {
    setSearch("")
    setCurrentPage(1)
    setPageSize(10)
    setStatus(["active"])
    setStatusCode("all")
    setDateUpdatedSort("desc")
    setFrequency(["daily"])
    setMirrorsData([])
    getProjectMirrors(1, 10, ["active"], "all", "desc", ["daily"], false).catch(error => {
      setLoading(false)
      setContentError("An error occurred while getting the list of mirrors.")
      console.log(`Error while getting workgroup mirrors: ${error}`)
      Sentry.captureException(error)
    })
  }

  const ActionItem = data => {
    return (
      <>
        <MateriaUiTooltip title="View Mirror Details">
          <Button
            outline
            color="dark"
            className="ml-1 border-0 p-0"
            id={`MirrorView-${data.mirrorID}`}
            onClick={() => showMirrorModal(data)}
          >
            <FaRegEye />
          </Button>
        </MateriaUiTooltip>
      </>
    )
  }

  const StatusItem = data => {
    const statusMap = {
      mirrored: <BsCheckCircle color="green" size={24} title="Mirrored" />,
      pending: <BsAlarm size={24} title="Pending" />,
      "in-progress": <BiLoader size={24} title="In-progress" />,
      // active: <BsCheckCircle color="green" size={24} title="Active" />,
      inactive: <RiAlarmWarningLine color="grey" size={24} title="Inactive" />,
      failed: <BsXCircle color="red" size={24} title="Failed" />,
    }
    return statusMap[data]
  }

  function UseToolTip(props) {
    const { item, show } = props
    return (
      <>
        <span id={`mirror-${show}-${item.mirrorID}`}>
          {item[show].startsWith("https://") || item[show].startsWith("http://") ? (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <a
                className="linkJump handlePointer"
                {...css(styles.link)}
                onClick={() => {
                  window.open(`${item[show]}`, "_blank", "noreferrer")
                }}
              >
                {item[show]}
              </a>
            </>
          ) : (
            item[show]
          )}
        </span>
      </>
    )
  }

  const mirrorModalRender = () => {
    return (
      <Form method="POST" {...css(styles.modal)}>
        <FormGroup>
          <Label for="mirrorID">Mirror ID</Label>
          <Input type="text" name="mirrorID" id="mirrorID" value={selectMirror.mirrorID} />
        </FormGroup>
        <FormGroup>
          <Label for="repoURLFrom">Repo URL From</Label>
          <Input type="text" name="repoURLFrom" id="repoURLFrom" value={selectMirror.repoURLFrom} />
        </FormGroup>
        <FormGroup>
          <Label for="repoURLTo">Repo URL To</Label>
          <Input type="text" name="repoURLTo" id="repoURLTo" value={selectMirror.repoURLTo} />
        </FormGroup>
        <FormGroup>
          <Label for="refSpaceFrom">Repo Space From</Label>
          <Input
            type="text"
            name="refSpaceFrom"
            id="refSpaceFrom"
            value={selectMirror.refSpaceFrom}
          />
        </FormGroup>
        <FormGroup>
          <Label for="refSpaceTo">Repo Space To</Label>
          <Input type="text" name="refSpaceTo" id="refSpaceTo" value={selectMirror.refSpaceTo} />
        </FormGroup>
        <FormGroup>
          <Label for="frequency">Frequency</Label>
          <Input
            type="text"
            name="frequency"
            id="frequency"
            value={_.upperFirst(selectMirror.frequency)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="excludeTags">Exclude Tags</Label>
          <Input
            type="text"
            name="excludeTags"
            id="excludeTags"
            value={_.upperFirst(selectMirror.excludeTags)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="status">Status</Label>
          <Input type="text" name="status" id="status" value={_.upperFirst(selectMirror.status)} />
        </FormGroup>
        <FormGroup>
          <Label>Status Note</Label>
          <Input
            type="text"
            name="created"
            id="created"
            value={_.upperFirst(selectMirror.statusNote)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Created Date</Label>
          <Input
            type="text"
            name="created"
            id="created"
            value={moment(selectMirror.created).format("YYYY-MM-DD HH:mm:ss")}
          />
        </FormGroup>
        <FormGroup>
          <Label>Last Updated Date</Label>
          <Input
            type="text"
            name="lastUpdated"
            id="lastUpdated"
            value={moment(selectMirror.lastUpdated).format("YYYY-MM-DD HH:mm:ss")}
          />
        </FormGroup>
        <FormGroup>
          <Label>Run Time</Label>
          <Input
            type="text"
            name="runTime"
            id="runTime"
            value={selectMirror.runTime ? `${selectMirror.runTime}S` : ""}
          />
        </FormGroup>
      </Form>
    )
  }

  return (
    <>
      {loading && (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          {...css(styles.loader)}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      )}
      <div style={{ opacity: loading ? ".5" : "1" }}>
        <Col>
          {!loading && contentError ? (
            <p {...css(styles.noData)}>{contentError}</p>
          ) : (
            <>
              <Row className="justify-content-center mb-2">
                <Col xs={{ size: 6 }} className="d-flex justify-content-center align-items-center">
                  <InputGroup>
                    <Input
                      value={search}
                      placeholder="Search for Mirror ID or URL From or URL To..."
                      onChange={event => handleSearchValue(event)}
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          event.preventDefault()
                          document.getElementById("searchButton").click()
                        }
                      }}
                    />
                    {search !== "" && (
                      <InputGroupAddon
                        className="clear_search"
                        addonType="append"
                        style={{ zIndex: 0 }}
                      >
                        <Button
                          onClick={() => {
                            setSearch("")
                            restartList()
                          }}
                        >
                          <MdClose />
                        </Button>
                      </InputGroupAddon>
                    )}
                    <InputGroupAddon addonType="append">
                      <Button id="searchButton" onClick={() => restartList(true)}>
                        <FaSearch />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <div className="d-flex justify-content-center align-items-center mb-2">
                  <div style={{ marginLeft: 50, width: 100 }}>
                    <FormControl className="mb-2" style={{ width: "100%" }}>
                      <InputLabel>Status Code</InputLabel>
                      <Select
                        labelId="statusCode"
                        id="status-code"
                        value={statusCode}
                        onChange={e => {
                          handleStatusCode(e.target.value)
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={302}>302</MenuItem>
                        <MenuItem value={403}>403</MenuItem>
                        <MenuItem value={404}>404</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Row>
              <div style={{ position: "absolute", right: 15, top: 15 }}>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={() => handleReset()}
                >
                  <strong>Reset</strong>
                </button>
              </div>
              {/* {mirrorsData.length > 0 ? ( */}
              <div id="mirrorsWrapper">
                {mirrorsData.length > 0 && (
                  <Row>
                    <Col className="d-flex justify-content-center align-items-center mb-2">
                      <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={onPageChange}
                        variant="outlined"
                        shape="rounded"
                      />
                      <div className="ml-2">
                        <Select
                          labelId="pageSize"
                          id="page-size"
                          value={pageSize}
                          onChange={onPageSizeChange}
                          className="pageSizeSelect"
                        >
                          <MenuItem value={10}>10 / page</MenuItem>
                          <MenuItem value={50}>50 / page</MenuItem>
                          <MenuItem value={100}>100 / page</MenuItem>
                        </Select>
                      </div>
                    </Col>
                    <div style={{ position: "absolute", right: 15 }}>
                      (Mirrors ({`${quantity}`}))
                    </div>
                  </Row>
                )}
                {mirrorsData.length === 0 && (
                  <Row>
                    <Col className="d-flex justify-content-end align-items-center mb-2">
                      <div>(Mirrors ({`${quantity}`}))</div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <div className="table-border" style={{ overflow: "auto" }}>
                      <Table>
                        <thead>
                          <tr>
                            <th>Detail</th>
                            <th>Repo URL From</th>
                            <th>Repo URL To</th>
                            <th>
                              Frequency&nbsp;&nbsp;
                              <AiOutlineFilter
                                color={frequency.length > 0 ? "#9c3" : ""}
                                className="filter-table"
                                onClick={e => {
                                  setFrequencyAnchorEl(e.currentTarget)
                                }}
                              />
                            </th>
                            <th>
                              Status&nbsp;&nbsp;
                              <AiOutlineFilter
                                color={status.length > 0 ? "#9c3" : ""}
                                className="filter-table"
                                onClick={e => {
                                  setStatusAnchorEl(e.currentTarget)
                                }}
                              />
                            </th>
                            <th>
                              Date Updated&nbsp;&nbsp;
                              <BiSortUp
                                color={dateUpdatedSort === "asc" ? "#9c3" : ""}
                                className="filter-table"
                                onClick={() => handleSort("asc")}
                              />
                              <BiSortDown
                                color={dateUpdatedSort === "desc" ? "#9c3" : ""}
                                className="filter-table"
                                onClick={() => handleSort("desc")}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {mirrorsData.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td width="6%">{ActionItem(item)}</td>
                                <td width="30%" className="word-break-all">
                                  <UseToolTip item={item} show="repoURLFrom" />
                                </td>
                                <td width="30%" className="word-break-all">
                                  <UseToolTip item={item} show="repoURLTo" />
                                </td>
                                <td width="10%">{_.upperFirst(item.frequency)}</td>
                                <td width="10%">
                                  {/* {StatusItem(item.status)} */}
                                  {item.deleted && (
                                    <>
                                      <span className="text-danger">
                                        <strong>Deleted</strong>
                                      </span>
                                    </>
                                  )}
                                  {!item.deleted && <> {StatusItem(item.status)}</>}
                                </td>
                                <td width="14%">
                                  {item.lastUpdated
                                    ? moment(item.lastUpdated).format("YYYY-MM-DD HH:mm:ss")
                                    : "-"}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
                {mirrorsData.length > 0 && (
                  <Row>
                    <Col className="d-flex justify-content-center align-items-center mb-2">
                      <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={onPageChange}
                        variant="outlined"
                        shape="rounded"
                      />
                      <div className="ml-2">
                        <Select
                          labelId="pageSize"
                          id="page-size"
                          value={pageSize}
                          onChange={onPageSizeChange}
                          className="pageSizeSelect"
                        >
                          <MenuItem value={10}>10 / page</MenuItem>
                          <MenuItem value={50}>50 / page</MenuItem>
                          <MenuItem value={100}>100 / page</MenuItem>
                        </Select>
                      </div>
                    </Col>
                  </Row>
                )}
                <Popover
                  open={!!frequencyAnchorEl}
                  anchorEl={frequencyAnchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  onClose={() => {
                    setFrequencyAnchorEl(null)
                  }}
                >
                  <div>
                    <Typography>
                      <div style={{ padding: 8 }}>
                        <div>
                          <Checkbox
                            checked={frequency.length === 3}
                            indeterminate={frequency.length !== 3 && frequency.length !== 0}
                            onChange={e => {
                              if (e.target.checked) {
                                handleFrequency("once,daily,weekly")
                              } else {
                                handleFrequency("")
                              }
                            }}
                          />{" "}
                          All
                        </div>
                        <div>
                          <Checkbox
                            checked={frequency.includes("once")}
                            onChange={e => handleFrequency("once", e.target.checked)}
                          />{" "}
                          Once
                        </div>
                        <div>
                          <Checkbox
                            checked={frequency.includes("daily")}
                            onChange={e => handleFrequency("daily", e.target.checked)}
                          />{" "}
                          Daily
                        </div>
                        <div>
                          <Checkbox
                            checked={frequency.includes("weekly")}
                            onChange={e => handleFrequency("weekly", e.target.checked)}
                          />{" "}
                          Weekly
                        </div>
                      </div>
                    </Typography>
                  </div>
                </Popover>
                <Popover
                  open={!!statusAnchorEl}
                  anchorEl={statusAnchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  onClose={() => {
                    setStatusAnchorEl(null)
                  }}
                >
                  <div>
                    <Typography>
                      <div style={{ padding: 8 }}>
                        <div>
                          <Checkbox
                            checked={status.length === 5}
                            indeterminate={status.length !== 5 && status.length !== 0}
                            onChange={e => {
                              if (e.target.checked) {
                                handleStatus("pending,in-progress,mirrored,inactive,failed")
                              } else {
                                handleStatus("")
                              }
                            }}
                          />{" "}
                          All
                        </div>
                        <div>
                          <Checkbox
                            checked={status.includes("pending")}
                            onChange={e => handleStatus("pending", e.target.checked)}
                          />{" "}
                          Pending
                        </div>
                        <div>
                          <Checkbox
                            checked={status.includes("in-progress")}
                            onChange={e => handleStatus("in-progress", e.target.checked)}
                          />{" "}
                          In-Progress
                        </div>
                        <div>
                          <Checkbox
                            checked={status.includes("mirrored")}
                            onChange={e => handleStatus("mirrored", e.target.checked)}
                          />{" "}
                          Mirrored
                        </div>
                        {/* <div>
                          <Checkbox
                            checked={status.includes("active")}
                            onChange={e => handleStatus("active", e.target.checked)}
                          />{" "}
                          Active
                        </div> */}
                        <div>
                          <Checkbox
                            checked={status.includes("inactive")}
                            onChange={e => handleStatus("inactive", e.target.checked)}
                          />{" "}
                          Inactive
                        </div>
                        <div>
                          <Checkbox
                            checked={status.includes("failed")}
                            onChange={e => handleStatus("failed", e.target.checked)}
                          />{" "}
                          Failed
                        </div>
                      </div>
                    </Typography>
                  </div>
                </Popover>
                <Modal
                  isOpen={mirrorModal}
                  toggle={() => setMirrorModal(!mirrorModal)}
                  backdrop="static"
                  zIndex={1500}
                  size="xl"
                  centered={true}
                  className={classes.modal}
                >
                  <ModalHeader toggle={() => setMirrorModal(!mirrorModal)}>
                    Mirror Detail
                  </ModalHeader>
                  <ModalBody>{mirrorModalRender()}</ModalBody>
                </Modal>
              </div>
              {mirrorsData.length === 0 && !loading && <p>There are no mirrors.</p>}
            </>
          )}
        </Col>
      </div>
    </>
  )
}

const styles = {}
