import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
// import UserBarChart from "../charts/users-chart-bar"
import { FaRedoAlt, FaRegFileExcel, FaFileExcel, FaFilePdf, FaFileCsv } from "react-icons/fa"
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Tooltip as MateriaUiTooltip,
} from "@material-ui/core"
import { Row, Col, Table, Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import Chart from "react-apexcharts"
import moment from "moment"
import { generateRandomInteger, getMonthYearList } from "../../../../../utils/common"
import FileSaver from "file-saver"
import { useUserOrgs } from "../../../../../hooks/use-user"
function RepoByIP(props) {
  //console.log(props)
  var [chartElementId, setChartElementId] = useState(null)
  const { getAccessTokenSilently } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      role: { isSystemAdmin, isSuperAdmin, isProjectAdmin },
    },
  } = useUserOrgs()
  const { orgIndex } = props
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  var [chartSettings, setChartSettings] = useState({ options: {}, series: {} })
  const monthsList = getMonthYearList(6)
  function getParentOrganization() {
    return props.parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (props.isSuperAdmin) {
      urlparams = `?parentOrganization=${getParentOrganization()}`
    } else {
      urlparams = `?index=${orgIndex}`
    }
    return urlparams
  }

  const handleExportToCSV = () => {
    const csvString = [
      ["Repo Path", "Pull Count"],
      ...dbRecords.map(item => [`"${item.projectpath}"`, `"${item.pullCount}"`]),
    ]
      .map(e => e.join(","))
      .join("\n")
    let exportContent = "\uFEFF"
    let blob = new Blob([exportContent + csvString], {
      type: "text/plain;charset=utrf-8",
    })
    FileSaver.saveAs(blob, `Repository Pull by IP-${moment().format("yyyy/MM/DD")}.csv`)
  }

  const LoadProjectReports = async () => {
    var urlparams = getAdditionalUrlParams()
    var urlparamsAmp = urlparams.replace("?", "&")
    // var orgCode = contextData.selectedParentOrganization.name
    const start = moment(props.startDate).format("yyyy-MM-DD")
    const end = moment(props.endDate).format("yyyy-MM-DD")

    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_get(
          `metrics/projects/${props.projectSlug}/git/projectdownloadsbyip/${
            props.ip
          }?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}&isproject=${
            props.isProject ? 1 : 0
          }`,
          accessToken
        )
          .then(response => {
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  const createElementIdForChart = () => {
    var id1 = generateRandomInteger(10000)
    var id2 = generateRandomInteger(10000)
    var newId = `${id1}${id2}`
    setChartElementId(newId)
  }
  // console.log(props, 'props')

  const getDataSource_for_graph = () => {
    var res = []

    res.push({
      name: "Active",
      data: [],
    })

    res = []
    // console.log(dbRecords.length)
    // console.log(dbRecords)
    dbRecords.forEach(record => {
      res.push(record.pullCount)
    })
    // console.log(res)
    return res
  }

  const get_graph_label = () => {
    var res = []

    // console.log(dbRecords.length)
    // console.log(dbRecords)
    dbRecords.forEach(record => {
      res.push(record.projectpath)
    })
    // console.log(res)
    return res
  }

  const drawBar = () => {
    // var dd = getDataSource_for_graph()
    const defaultColor = [
      "#A4C950",
      "#545FA9",
      "#6c757d",
      "#AE67A6",
      "#C2C319",
      "#349292",
      "#CA9657",
      "#F18F59",
      "#638FEA",
      "#B99C2C",
    ]

    var cs = {
      series: getDataSource_for_graph(),
      options: {
        chart: {
          // width: 380,
          type: "pie",
        },
        labels: get_graph_label(),
        responsive: [
          {
            // breakpoint: 480,
            options: {
              // chart: {
              //   width: 200,
              // },
              legend: {
                position: "left",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return "Pull Count: " + val
            },
            title: {
              formatter: function (seriesName) {
                return seriesName + "<br/>------------------------------<br/>"
              },
            },
          },
        },
      },
    }

    setChartSettings(cs)
  }

  useEffect(() => {
    // console.log(contextData)
    // if (contextData.submitCounter === 0) return
    // if (_.isNil(contextData.selectedParentOrganization)) {
    // console.log("from load users", contextData.selectedParentOrganization)
    if (props.ip === null) return
    if (props.startDate === undefined || props.startDate === null || props.startDate === "") return
    if (props.endDate === undefined || props.endDate === null || props.endDate === "") return

    setdbRecords([])
    setShow(true)
    LoadProjectReports()
  }, [props.ip])

  useEffect(() => {
    if (chartElementId === null) return
    drawBar()
  }, [chartElementId])

  useEffect(() => {
    createElementIdForChart()
  }, [dbRecords])

  useEffect(() => {
    console.log(props)
  }, [props])

  return (
    <React.Fragment>
      <>
        {/* <div>Top 10 Repo by IP with pull counts</div> */}

        <div id="accounts-total-users-data">
          {!loadStatus.loading && !loadStatus.error && (
            <>
              <Row className="mb-2">
                {/* <Col xs={{ size: 1 }}>
                  <FormControl className="mb-2" style={{ width: "100%" }}>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => LoadProjectReports()}
                    >
                      <strong>Go</strong>
                    </button>
                  </FormControl>
                </Col> */}
                {!loadStatus.loading && !loadStatus.error && (
                  <>
                    <Col>
                      <div className="float-left">
                      <strong>IP: </strong>{props.ip}&nbsp;&nbsp; <strong>Country: </strong>{props.data.countryName}&nbsp;&nbsp; <strong>Organization: </strong>{props.data.companyName}&nbsp;&nbsp; <strong>Period: </strong>{monthsList.find(m=>m.firstDay === props.startDate)?.value || '-'}
                      </div>
                      {dbRecords.length > 0 && (
                        <>
                          <div className="float-right">
                            <button
                              type="button"
                              className="btn btn-outline-dark btn-sm"
                              onClick={() => LoadProjectReports()}
                            >
                              {/* <strong>Refresh</strong> */}
                              <FaRedoAlt />
                            </button>
                            &nbsp;
                            <button
                              type="button"
                              className="btn btn-outline-success btn-sm"
                              onClick={() => {
                                handleExportToCSV()
                                // window["exportTableToExcel"]("repo-data-usage-excel", "", "Repo-data-usage")
                              }}
                            >
                              {/* <strong>excel</strong> */}
                              <FaFileCsv />
                            </button>
                            &nbsp;
                            <button
                              type="button"
                              className="btn btn-outline-success btn-sm"
                              onClick={() =>
                                window["generatePDF"]("git-pull-data-by-ip", "Repository Pull by IP")
                              }
                            >
                              <FaFilePdf />
                            </button>
                          </div>
                        </>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </>
          )}

          <div>
            {loadStatus.loading && !loadStatus.error && (
              <>
                <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading repo by
                ip...
              </>
            )}
            {!loadStatus.loading && !loadStatus.error && (
              <>
                {dbRecords.length <= 0 && <>Sorry ! No Info found</>}
                {dbRecords.length > 0 && (
                  <>
                    <div id="git-pull-data-by-ip">
                      {/* <span style={{ display: "none" }}>User Accounts Report</span> */}
                      <table className="table table-sm" id="git-pull-data-by-ip-excel">
                        <thead>
                          <th>Repository</th>
                          <th>Pull Count</th>
                        </thead>
                        <tbody>
                          {/* <tr>
                                <td>Created this period</td>
                                <td>
                                  <span className="float-right">{dbRecords[0].cnt}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>Became inactive this period</td>
                                <td>
                                  <span className="float-right">{dbRecords[1].cnt}</span>
                                </td>
                              </tr> */}
                          {dbRecords.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.projectpath}</td>

                                <td>{item.pullCount}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>

                      <Chart
                        options={chartSettings.options}
                        series={chartSettings.series}
                        type="pie"
                        width="80%"
                        height="400px"
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {!loadStatus.loading && loadStatus.error && (
              <>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="alert alert-danger py-1" role="alert">
                  {loadStatus.errorMessage}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </React.Fragment>
  )
}

export default RepoByIP
