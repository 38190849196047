import React from "react"
import { Row, Col } from "reactstrap"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"
import _ from "lodash"
import { css } from "glamor"
import { ServiceDisplayName } from "../../../../utils/service-mappings"

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}))

export default function Overview(props) {
  const classes = useStylesBootstrap()
  const {
    name,
    description,
    projectSlug,
    visibility,
    status,
    gitlabURL,
    wikiURL,
    artifactoryURL,
  } = props
  function BootstrapTooltip(props) {
    return <Tooltip ref={props.ref} arrow classes={classes} {...props} placement="top-start" />
  }
  const classMap = {
    active: "text-primary",
    inactive: "text-warning",
    archived: "text-black-50",
  }
  const overViewImg = (serviceName, url) => {
    const text = ServiceDisplayName(serviceName)
    return (
      <>
        {url ? (
          <Col xm={1} sm={1} md={1}>
            <BootstrapTooltip
              title={text}
              placement="top"
              onClick={event => {
                window.open(url, "_blank", "noreferrer")
              }}
              {...css(styles.navLink)}
            >
              <img height="30" src={`/${serviceName}.svg`} alt="service" />
            </BootstrapTooltip>
          </Col>
        ) : null}
      </>
    )
  }
  return (
    <div>
      <Row className="mb-3 mt-4">
        <Col xm={2} sm={2} md={2} className="text-right">
          Name:
        </Col>
        <Col>{name}</Col>
      </Row>
      <Row className="mb-3">
        <Col xm={2} sm={2} md={2} className="text-right">
          Description:
        </Col>
        <Col><div dangerouslySetInnerHTML={{ __html: description }}></div></Col>
      </Row>
      <Row className="mb-3">
        <Col xm={2} sm={2} md={2} className="text-right">
          Slug:
        </Col>
        <Col>{projectSlug}</Col>
      </Row>
      <Row className="mb-3">
        <Col xm={2} sm={2} md={2} className="text-right">
          Visibility:
        </Col>
        <Col>{_.upperFirst(visibility)}</Col>
      </Row>
      <Row className="mb-3">
        <Col xm={2} sm={2} md={2} className="text-right">
          Status:
        </Col>
        <Col className={classMap[status]}>{_.upperFirst(status)}</Col>
      </Row>
      <Row className="mb-3">
        <Col xm={2} sm={2} md={2} className="text-right">
          Services:
        </Col>
        <Col>
          <Row>
            {overViewImg("gitlab", gitlabURL)}
            {overViewImg("artifactory", artifactoryURL)}
            {overViewImg("wiki", wikiURL)}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const styles = {
  navLink: {
    cursor: "pointer",
  },
}
