import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Select, MenuItem, FormControl } from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
import "../../../../../styles/metrics.scss"
import { getMonthYearList } from "../../../../../utils/common"
import _ from "lodash"
import CISummaryBox from "./summaryBox"
import Pipelinetabular from "./pipeline-tabular"

export default function MetricsProjecCIControl(props) {
  const monthsList = getMonthYearList(6)
  monthsList.sort((a, b) => (parseInt(a.key) < parseInt(b.key) ? 1 : -1))
  if (monthsList[0] !== undefined && monthsList[0] !== null)
    monthsList[0].value += ` | Current Month to date`
  if (monthsList[1] !== undefined && monthsList[1] !== null)
    monthsList[1].value += ` | Previous Month`

  const queryParams = new URLSearchParams(window.location.search)
  var qm = queryParams.get("m")
  if (qm !== undefined && qm !== null) qm = monthsList.find(n => n.key === qm).value
  else qm = monthsList[0].value

  const [selectedMonth, setSelectedMonth] = useState(props.queryParams.get("selectedMonth") || qm)
  const [selectedMonthAttributes, setSelectedMonthAttributes] = useState(null)
  const [searchCounter, setSearchCounter] = useState(0)
  const handleMonthChange = event => {
    setSelectedMonth(event.target.value)
  }

  const doSearch = () => {
    setSearchCounter(n => n + 1)
  }

  useEffect(() => {
    let urlQuery = "?t=CI-Usage"
    if (selectedMonth) urlQuery += `&selectedMonth=${selectedMonth}`
    window.history.pushState("", "", window.location.origin + window.location.pathname + urlQuery)
  }, [searchCounter])
  useEffect(() => {
    var val = monthsList.find(n => n.value === selectedMonth)
    setSelectedMonthAttributes(val)
  }, [selectedMonth]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row className="mb-2">
        <Col xs={{ size: 4 }}>
          {monthsList !== null && (
            <>
              <FormControl className="mb-2" style={{ width: "100%" }}>
                <Select value={selectedMonth} onChange={handleMonthChange}>
                  {monthsList.map((p, index) => (
                    <MenuItem key={p.key} value={p.value} style={{ height: 36 }}>
                      {p.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Col>
        <Col xs={{ size: 1 }}>
          <FormControl className="mb-2" style={{ width: "100%" }}>
            <button type="button" className="btn btn-primary btn-sm" onClick={() => doSearch()}>
              <strong>Go</strong>
            </button>
          </FormControl>
        </Col>
      </Row>
      {selectedMonthAttributes !== null &&
        selectedMonthAttributes !== undefined &&
        selectedMonthAttributes.firstDay !== undefined &&
        selectedMonthAttributes.lastDay !== undefined && (
          <>
            <CISummaryBox
              startDate={selectedMonthAttributes.firstDay}
              endDate={selectedMonthAttributes.lastDay}
              counter={searchCounter}
              parentOrg={props.parentOrg}
              projectSlug={props.projectSlug}
              orgIndex={props.orgIndex}
            />
            <br />
            <Pipelinetabular
              startDate={selectedMonthAttributes.firstDay}
              endDate={selectedMonthAttributes.lastDay}
              counter={searchCounter}
              parentOrg={props.parentOrg}
              projectSlug={props.projectSlug}
              orgIndex={props.orgIndex}
            />
          </>
        )}
    </>
  )
}
