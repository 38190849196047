import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/gatsby"
import { fetch_get } from "../../../../../utils/fetch"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../../../../../components/loading"
import moment from "moment"
import { Table } from "reactstrap"
import { toast } from "react-toastify"

export default function MetricsProjectDetail(props) {
  const { selectFile, repokey, urlparams } = props
  const { getAccessTokenSilently } = useAuth0()
  const [fetchingFileDetail, setFetchingFileDetail] = useState(false)
  const [fileDetail, setFileDetail] = useState({})
  const [fileDetailError, setFileDetailError] = useState(false)

  useEffect(() => {
    //console.log(props.isSuperAdmin, selectFile)
    if (selectFile.uri) {
      setFetchingFileDetail(true)
      setFileDetailError(false)
      setFileDetail({})
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `projects/${props.projectId}/artifactory/${repokey}/file${selectFile.path}${urlparams}`,
            accessToken
          )
        )
        .then(response => {
          setFetchingFileDetail(false)
          setFileDetail(response)
        })
        .catch(error => {
          Sentry.captureException(error)
          console.log(`Error while getting artifacts file details: ${error}`)
          toast(`${error}`, {
            type: "error",
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          setFileDetailError(true)
          setFetchingFileDetail(false)
        })
    }
  }, [selectFile]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {fetchingFileDetail ? (
        <Loading />
      ) : fileDetailError ? (
        <span>
          An error has occurred while trying to retrieve the information for file. If this
          continues, please raise a support ticket.
        </span>
      ) : (
        <>
          <div className="table-border">
            <Table size="sm" bordered>
              <tbody>
                <tr>
                  <th scope="row" style={{ width: 150 }}>
                    Source File Path
                  </th>
                  <td colspan={3} style={{ wordBreak: "break-all" }}>
                    {fileDetail.sourceFilePath}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: 150 }}>
                    Download URL
                  </th>
                  <td colspan={3} style={{ wordBreak: "break-all" }}>
                    {fileDetail.downloadURL}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: 150 }}>
                    Download Count
                  </th>
                  <td>{fileDetail.downloadCount}</td>
                  <th scope="row" style={{ width: 150 }}>
                    Last Downloaded
                  </th>
                  <td>
                    {fileDetail.lastDownloaded
                      ? moment(fileDetail.lastDownloaded).utc().format("YYYY-MM-DD HH:mm:ss")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: 150 }}>
                    Created
                  </th>
                  <td>
                    {fileDetail.created
                      ? moment(fileDetail.created).utc().format("YYYY-MM-DD HH:mm:ss")
                      : "-"}
                  </td>
                  <th scope="row" style={{ width: 150 }}>
                    Last Modified
                  </th>
                  <td>
                    {fileDetail.lastModified
                      ? moment(fileDetail.lastModified).utc().format("YYYY-MM-DD HH:mm:ss")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: 150 }}>
                    Mime Type
                  </th>
                  <td>{fileDetail.mimeType}</td>
                  <th scope="row" style={{ width: 150 }}>
                    Size
                  </th>
                  <td>{fileDetail.size}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: 150 }}>
                    Status
                  </th>
                  <td>{fileDetail.status}</td>
                  <th scope="row" style={{ width: 150 }}>
                    Legacy
                  </th>
                  <td>{fileDetail.metadata?.legacy.toString()}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: 150 }}>
                    Status Note
                  </th>
                  <td colspan={3} style={{ wordBreak: "break-all" }}>
                    {fileDetail.status_note?.errors.map(e=><div>{e.message}</div>) || '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {fileDetail.metadata?.legacy &&
          fileDetail.metadata?.legacy.toString().toLowerCase() === "true" ? null : (
            <>
              <h5>Metadata</h5>
              <div className="table-border">
                <Table size="sm" bordered>
                  <tbody>
                    <tr>
                      <th scope="row" style={{ width: 150 }}>
                        Copyright Text
                      </th>
                      <td colspan={3} style={{ wordBreak: "break-all" }}>
                        {fileDetail.metadata?.copyrightText}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: 150 }}>
                        License Text
                      </th>
                      <td colspan={3} style={{ wordBreak: "break-all" }}>
                        {fileDetail.metadata?.licenseText}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: 150 }}>
                        License Identifier
                      </th>
                      <td>{fileDetail.metadata?.licenseIdentifier}</td>
                      <th scope="row" style={{ width: 150 }}>
                        Modification
                      </th>
                      <td>{fileDetail.metadata?.modification}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: 150 }}>
                        Package Name
                      </th>
                      <td>{fileDetail.metadata?.packageName}</td>
                      <th scope="row" style={{ width: 150 }}>
                        Package Version
                      </th>
                      <td>{fileDetail.metadata?.packageVersion}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: 150 }}>
                        Visibility
                      </th>
                      <td colspan={3}>{fileDetail.metadata?.visibility}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
