import React, { useState, useEffect } from "react"
import "react-datepicker/dist/react-datepicker.css"
import "../../../../../styles/metrics.scss"
import _ from "lodash"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_get } from "../../../../../utils/fetch"

export default function MetricsProjecCISummaryBoxes(props) {
  const queryParams = new URLSearchParams(window.location.search)
  const qtab = queryParams.get("t")

  const [loadingStatus, setLoadingStatus] = useState({
    loading: false,
    error: false,
    errorMessage: null,
  })
  const [summeryData, setSummeryData] = useState({})
  const { getAccessTokenSilently } = useAuth0()
  const getSummaryData = () => {
    setLoadingStatus({ loading: true, error: false, errorMessage: null })
    let queryParams = `?startDate=${props.startDate}&endDate=${props.endDate}&parentOrganization=${
      props.parentOrg
    }${_.isNil(props.orgIndex) ? "" : `&index=${props.orgIndex}`}`

    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          `metrics/projects/${props.projectSlug}/ci/pipelines/counts${queryParams}`,
          accessToken
        )
      )
      .then(response => {
        setSummeryData(response)
        setLoadingStatus({ loading: false, error: false, errorMessage: null })
      })
      .catch(error => {
        setLoadingStatus({ loading: true, error: true, errorMessage: error.message })
      })
  }

  useEffect(() => {
    getSummaryData()
  }, [props.counter]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loadingStatus.loading && !loadingStatus.error && (
        <>
          <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading Summary Info..
        </>
      )}
      {!loadingStatus.loading && !loadingStatus.error && summeryData !== {} && (
        <>
          <div className="row row-cols-1 row-cols-md-4 row-cols-xl-5">
            <div className="col">
              <div className="card radius-10 border-info">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Total Pipelines</p>
                      <h4 className="my-1 text-info">{summeryData.totalPipelines}</h4>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                      <i className="fa fa-shopping-cart"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-danger">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Total Cost</p>
                      <h4 className="my-1 text-danger">${new Number(summeryData.totalCost).toFixed(2)}</h4>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                      <i className="fa fa-dollar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-info">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Total Duration</p>
                      <h4 className="my-1 text-success">{summeryData.duration} Second(s)</h4>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                      <i className="fa fa-bar-chart"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-danger">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Failed Pipelines</p>
                      <h4 className="my-1 text-success">{summeryData.failed_pipelines || 0}</h4>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                      <i className="fa fa-bar-chart"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-success">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Success Pipelines</p>
                      <h4 className="my-1 text-success">{summeryData.success_pipelines || 0}</h4>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                      <i className="fa fa-bar-chart"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
