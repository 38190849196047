import React from "react"
import { useUserOrgs } from "../../hooks/use-user"
import MetricsProjectDetail from "../../components/metrics/projects/metrics-project-detail"
import "../../styles/metrics.scss"
import { Loading } from "../../components/loading"

const ProjectDetailPage = props => {
  const {
    loading,
    currentOrg: { index, name, role: { isSystemAdmin, isSuperAdmin }, },
  } = useUserOrgs()
  const { id, parentOrganization, location } = props

  if (loading) {
    return <Loading />
  }

  return (
    <MetricsProjectDetail
      {...props}
      projectId={id}
      parentOrganization={parentOrganization}
      orgIndex={index}
      location={location}
      isSystemAdmin={isSystemAdmin}
      isSuperAdmin={isSuperAdmin}
      parentOrgName={name}
    />
  )
}

export default ProjectDetailPage
