import React, { useState, useEffect } from "react"
import { Row, Col, Table } from "reactstrap"
import { fetch_get } from "../../../../utils/fetch"
import { PropagateLoader } from "react-spinners"
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core"
import * as Sentry from "@sentry/gatsby"
import Pagination from "@material-ui/lab/Pagination"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import moment from "moment"
import { useUserOrgs } from "../../../../hooks/use-user"

export default function UsersForOrganization(props) {
  const { orgIndex, projectSlug, roles, queryParams } = props
  const { getAccessTokenSilently } = useAuth0()
  const [userList, setUserList] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [role, setRole] = useState(queryParams.get('role')||'')
  const [noUsers, setNoUsers] = useState("No users match the criteria")
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(queryParams.get('page')?parseInt(queryParams.get('page')):1)
  const [idleFrom, setIdleFrom] = useState(queryParams.get('idleFrom')||'')
  const [initData, setInitData] = useState(true)
  const getUrl = _.isNil(orgIndex)
    ? `metrics/projects/${projectSlug}/users/idle?page`
    : `metrics/projects/${projectSlug}/users/idle?index=${orgIndex}&page`

  const {
    currentOrg: {
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  function getParentOrganization() {
    return props.parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (isSuperAdmin) {
      urlparams = `&parentOrganization=${getParentOrganization()}`
    }
    return urlparams
  }
  function onPageChange(event, page) {
    setCurrentPage(page)
    let url = `${getUrl}=${page}`
    var urlparams = getAdditionalUrlParams()
    url = url + urlparams
    get_user_list(filterCondition(url, true))
  }

  const handleRoleChange = event => {
    setRole(event.target.value)
  }

  function DisplayUserList(props) {
    const userList = props.users
    if (userList.length === 0) {
      return (
        <Row>
          <Col xs={{ size: 12 }}>
            <p>{noUsers}</p>
          </Col>
        </Row>
      )
    }

    return (
      <div className="table-border">
        <Table>
          <thead>
            <tr>
              <th>Display Name</th>
              <th>Username</th>
              <th>Status</th>
              <th>Project Role</th>
              <th>Email</th>
              <th>Last Login</th>
            </tr>
          </thead>
          <tbody>
            {userList.map((item, index) => {
              return (
                <tr key={index}>
                  <td width="15%">{item.displayName}</td>
                  <td width="10%">{item.username}</td>
                  <td width="10%">{_.startCase(item.active)}</td>
                  <td width="10%">{_.startCase(item.projectRole)}</td>
                  <td width="15%">{item.email}</td>
                  <td width="20%">
                    {item.dateLastAccessed
                      ? moment(item.dateLastAccessed).format("YYYY-MM-DD HH:mm:ss")
                      : "-"}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }

  useEffect(() => {
    if (props.parentOrganization === undefined || props.parentOrganization === null) return
    if (isSuperAdmin === undefined || isSuperAdmin === null) return

    var urlparams = ""
    if (isSuperAdmin) {
      urlparams = `&parentOrganization=${props.parentOrganization}`
    }
    let url = `${getUrl}=1${urlparams}`
    if (initData) {
      const page = queryParams.get('page')?parseInt(queryParams.get('page')):1
      url = `${getUrl}=${page}${urlparams}`
      setCurrentPage(page)
    } else {
      setCurrentPage(1)
    }


    get_user_list(filterCondition(url, true))
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, idleFrom, props.parentOrganization, isSuperAdmin])

  function get_user_list(url) {
    setLoadingData(true)
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setUserList(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
        setLoadingData(false)
        setInitData(false)
      })
      .catch(error => {
        console.log(`Error while getting list of users: ${error}`)
        Sentry.captureException(error)
        setUserList([])
        setNoUsers("An error occurred while getting the list of users.")
        setLoadingData(false)
        setInitData(false)
      })
  }

  const filterCondition = url => {
    let params = new URLSearchParams(url.substring( url.indexOf("?")))
    let urlparams = `?t=users&page=${params.get('page')}`
    if (role !== "" && role !== "all") {
      url += `&projectRole=${role}`
      urlparams += `&role=${role}`
    }
    if (idleFrom !== "") {
      url += `&idleFrom=${moment().subtract(idleFrom, "days").format("YYYY-MM-DD")}`
      urlparams += `&idleFrom=${idleFrom}`
    }
    window.history.pushState(
      "",
      "",
      window.location.origin + window.location.pathname + urlparams
    )
    return url
  }

  return (
    <div className="d-flex flex-column" id="usersWrapper">
      <>
        <Row className="mb-2">
          <Col xs={{ size: 4 }}>
            <FormControl className="mb-2" style={{ width: "100%" }}>
              <InputLabel>Role</InputLabel>
              <Select value={role} onChange={handleRoleChange}>
                {[""].concat(roles).map((p, index) => (
                  <MenuItem key={index} value={p} style={{ height: 36 }}>
                    {_.startCase(p)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col xs={{ size: 4 }}>
            <FormControl className="mb-2" style={{ width: "100%" }}>
              <InputLabel>Idle for</InputLabel>
              <Select value={idleFrom} onChange={event => setIdleFrom(event.target.value)}>
                <MenuItem value="" style={{ height: 36 }}></MenuItem>
                <MenuItem value="30">30 days</MenuItem>
                <MenuItem value="90">3 months</MenuItem>
                <MenuItem value="180">6 months</MenuItem>
                <MenuItem value="365">1 year</MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Row>
      </>
      {loadingData ? (
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center pt-4"
            xs={{ size: 12 }}
            sm={{ size: 4, offset: 4 }}
          >
            <PropagateLoader
              className="align-self-center"
              size={20}
              color={"#9c3"}
              loading={true}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col className="d-flex justify-content-center align-items-center mb-2">
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={onPageChange}
                variant="outlined"
                shape="rounded"
              />
            </Col>
          </Row>
          <DisplayUserList users={userList} />
          <Row>
            <Col className="d-flex justify-content-center align-items-center mt-2">
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={onPageChange}
                variant="outlined"
                shape="rounded"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
