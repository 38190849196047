import React from "react"
import { Row, Col } from "reactstrap"
import { css } from "glamor"

export default function Licenses(props) {
  const { licensesData } = props

  return licensesData.length > 0 ? (
    <Row>
      <Col>
        <ul>
          {licensesData.map((item, index) => (
            <li>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <a
                className="linkJump handlePointer"
                {...css(styles.liceneseName)}
                onClick={() => {
                  window.open(`${item.link}`, "_blank", "noreferrer")
                }}
              >{`${item.fullname} (${item.name})`}</a>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  ) : (
    <p>There are no licenses.</p>
  )
}

const styles = {
  liceneseName: {
    width: "500px",
  },
}
