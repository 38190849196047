import React, { useState } from "react"
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import RepoList from "./git/repo-list"
import Contribution from "./git/git-contribution"
import classnames from "classnames"
import { css } from "glamor"
import "../../../../styles/metrics.scss"
import _ from 'lodash'
export default function MetricsProjectDetail(props) {
  const [tabsName] = useState([
    { id: "Repos", value: "Repos" },
    { id: "Contribution", value: " Contribution" },
  ])
  const [activeTab, setActiveTab] = useState(_.startCase(props.queryParams.get("st"))||"Repos")

  const changeTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const navLink = tabsName => {
    return tabsName.map((item, index) => {
      return (
        <NavItem key={item.id}>
          <NavLink
            className={classnames({ active: activeTab === item.id })}
            onClick={() => {
              changeTab(item.id)
            }}
            key={item.id}
            {...css(styles.navLink)}
          >
            {item?.value}
          </NavLink>
        </NavItem>
      )
    })
  }

  return (
    <>
      <Nav tabs>{navLink(tabsName)}</Nav>
      <TabContent activeTab={activeTab} {...css(styles.tabContent)}>
        <TabPane tabId="Repos">{activeTab === "Repos" && <RepoList {...props} />}</TabPane>
        <TabPane tabId="Contribution">{activeTab === "Contribution" && <Contribution {...props} />}</TabPane>
      </TabContent>
    </>
  )
}

const styles = {
  noData: {
    marginTop: "45px",
  },
  form: {
    maxHeight: "calc(100vh - 200px)",
    overflowY: "auto",
  },
  tabContent: {
    height: "100%",
    width: "100%",
    marginTop: "25px",
  },
  navLink: {
    cursor: "pointer",
  },
}
