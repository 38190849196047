import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
// import UserBarChart from "../charts/users-chart-bar"
import {
  FaRedoAlt,
  FaRegFileExcel,
  FaRegEye,
  FaFileExcel,
  FaFilePdf,
  FaFileCsv,
} from "react-icons/fa"
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Tooltip as MateriaUiTooltip,
} from "@material-ui/core"
import { Row, Col, Table, Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import Chart from "react-apexcharts"
import moment from "moment"
import { generateRandomInteger } from "../../../../../utils/common"
import FileSaver from "file-saver"
import { useUserOrgs } from "../../../../../hooks/use-user"
import RepoByIP from "./repoByIP"

function ProjectsByIP(props) {
  const useStyles = makeStyles(() => ({
    modal: {
      width: 500,
    },
  }))
  //console.log(props)
  var [chartElementId, setChartElementId] = useState(null)
  const { getAccessTokenSilently } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      role: { isSystemAdmin, isSuperAdmin, isProjectAdmin },
    },
  } = useUserOrgs()
  const { orgIndex } = props
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })
  var [loadIPStatus, setLoadIPStatus] = useState({
    loading: false,
    error: false,
    errorMessage: null,
  })
  var [selectedIp, setSelectedIp] = useState(null)
  var [clickedIP, setClickedIP] = useState(null)
  var [selectPullData, setSelectPullData] = useState(null)
  var [chartSettings, setChartSettings] = useState({ options: {}, series: {} })
  const [gitPullTopIp, setGitPullTopIp] = useState([])
  const [iplist, setIpList] = useState(props.iplist)
  var [openModal, setopenModal] = React.useState(false)
  const classes = useStyles()

  const handleIPChange = event => {
    setSelectedIp(event.target.value)
  }

  function getParentOrganization() {
    return props.parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (props.isSuperAdmin) {
      urlparams = `?parentOrganization=${getParentOrganization()}`
    } else {
      urlparams = `?index=${orgIndex}`
    }
    return urlparams
  }

  const handleExportToCSV = () => {
    const csvString = [
      ["Repo Path", "Pull Count"],
      ...dbRecords.map(item => [`"${item.projectpath}"`, `"${item.pullCount}"`]),
    ]
      .map(e => e.join(","))
      .join("\n")
    let exportContent = "\uFEFF"
    let blob = new Blob([exportContent + csvString], {
      type: "text/plain;charset=utrf-8",
    })
    FileSaver.saveAs(blob, `Project Pull by IP-${moment().format("yyyy/MM/DD")}.csv`)
  }

  const handleIPInfoExportToCSV = () => {
    let csvString = [
      ["IP", "Country Name", "Company Name"],
      ...iplist.map(item => [
        `"${item.name}"`,
        `"${item.countryName}"`,
        `"${item.companyName === null ? "" : item.companyName}"`,
      ]),
    ]
      .map(e => e.join(","))
      .join("\n")

    if (props.type === 'Data Transfered by Percentage') {
      csvString = [
        ["IP", "Country Name", "Company Name", "Usage" ],
        ...iplist.map(item => [
          `"${item.name}"`,
          `"${item.countryName}"`,
          `"${item.companyName === null ? "" : item.companyName}"`,
          `"${item.percentage === null ? "" : item.percentage}"`,
        ]),
      ]
        .map(e => e.join(","))
        .join("\n")
    }
    let exportContent = "\uFEFF"
    let blob = new Blob([exportContent + csvString], {
      type: "text/plain;charset=utrf-8",
    })
    FileSaver.saveAs(blob, `Project-top-10-ip-${moment().format("yyyy/MM/DD")}.csv`)
  }

  const LoadProjectReports = async () => {
    var urlparams = getAdditionalUrlParams()
    var urlparamsAmp = urlparams.replace("?", "&")
    // var orgCode = contextData.selectedParentOrganization.name
    const start = moment(props.startDate).format("yyyy-MM-DD")
    const end = moment(props.endDate).format("yyyy-MM-DD")

    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_get(
          `metrics/projects/${
            props.projectSlug
          }/git/projectdownloadsbyip/${selectedIp}?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}&isproject=${
            props.isProject ? 1 : 0
          }`,
          accessToken
        )
          .then(response => {
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  const LoadIps = async () => {
    var urlparams = getAdditionalUrlParams()
    var urlparamsAmp = urlparams.replace("?", "&")
    // var orgCode = contextData.selectedParentOrganization.name
    const start = moment(props.startDate).format("yyyy-MM-DD")
    const end = moment(props.endDate).format("yyyy-MM-DD")

    setLoadIPStatus({ loading: true, error: false, errorMessage: null })
    setIpList([])
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_get(
          props.type !== 'Data Transfered by Percentage'? `metrics/projects/${props.projectSlug}/git/pull/topIps?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}`:
          `metrics/projects/${props.projectSlug}/git/pull/payload_bytes/topIps?topN=10${urlparamsAmp}&startDate=${start}&endDate=${end}`,
          accessToken
        )
          .then(response => {
            // console.log(response)
            setIpList(
              response.map(res => ({
                name: res.ip,
                value: res.pullCount,
                countryName: res.countryName,
                companyName: res.organizationName,
                percentage: res.percentage
              }))
            )
            setLoadIPStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadIPStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadIPStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  const createElementIdForChart = () => {
    var id1 = generateRandomInteger(10000)
    var id2 = generateRandomInteger(10000)
    var newId = `${id1}${id2}`
    setChartElementId(newId)
  }
  // console.log(props, 'props')

  const getDataSource_for_graph = () => {
    var res = []

    res.push({
      name: "Active",
      data: [],
    })

    res = []
    // console.log(dbRecords.length)
    // console.log(dbRecords)
    dbRecords.forEach(record => {
      res.push(record.pullCount)
    })
    // console.log(res)
    return res
  }

  const get_graph_label = () => {
    var res = []

    // console.log(dbRecords.length)
    // console.log(dbRecords)
    dbRecords.forEach(record => {
      res.push(record.projectpath)
    })
    // console.log(res)
    return res
  }

  const drawBar = () => {
    // var dd = getDataSource_for_graph()
    const defaultColor = [
      "#A4C950",
      "#545FA9",
      "#6c757d",
      "#AE67A6",
      "#C2C319",
      "#349292",
      "#CA9657",
      "#F18F59",
      "#638FEA",
      "#B99C2C",
    ]

    var cs = {
      series: getDataSource_for_graph(),
      options: {
        chart: {
          // width: 380,
          type: "pie",
        },
        labels: get_graph_label(),
        responsive: [
          {
            // breakpoint: 480,
            options: {
              // chart: {
              //   width: 200,
              // },
              legend: {
                position: "left",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return "Pull Count: " + val
            },
            title: {
              formatter: function (seriesName) {
                return seriesName + "<br/>------------------------------<br/>"
              },
            },
          },
        },
      },
    }

    setChartSettings(cs)
  }

  // useEffect(() => {
  //   if (selectedIp === null) return
  //   setdbRecords([])
  //   setShow(true)
  //   LoadProjectReports()
  // }, [selectedIp])

  useEffect(() => {
    if (chartElementId === null) return
    drawBar()
  }, [chartElementId])

  useEffect(() => {
    createElementIdForChart()
  }, [dbRecords])

  useEffect(() => {
    if (props.startDate === undefined || props.startDate === null || props.startDate === "") return
    if (props.endDate === undefined || props.endDate === null || props.endDate === "") return
    if (props.iplist === undefined || props.iplist === null || props.iplist.length <= 0) {
      setdbRecords([])
      setShow(true)

      LoadIps()
    }
  }, [props.iplist])

  useEffect(() => {
    if (props.startDate === undefined || props.startDate === null || props.startDate === "") return
    if (props.endDate === undefined || props.endDate === null || props.endDate === "") return
    if (iplist === undefined || iplist === null || iplist.length <= 0) {
      if (iplist.length <= 0) {
        setShow(true)
        setLoadStatus({ loading: false, error: false, errorMessage: null })
      }
      return
    }
    setSelectedIp(iplist[0].name)
  }, [iplist, props.startDate, props.endDate])

  useEffect(() => {
    if (clickedIP === undefined || clickedIP === null) return
    setopenModal(true)
  }, [clickedIP])

  useEffect(() => {
    console.log(props)
  }, [props])

  return (
    <React.Fragment>
      <>
        <hr />
        <div className="row">
          <Col>
            <div className="float-left">Top 10 IP's ({props.type})</div>
            {loadIPStatus.loading && !loadIPStatus.error && (
              <>
                <br />
                <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading IP...
              </>
            )}
            {!loadIPStatus.loading && !loadIPStatus.error && (
              <>
                {iplist.length <= 0 && (
                  <>
                    <br />
                    <div>Sorry ! No IP found</div>
                  </>
                )}
                {iplist.length > 0 && (
                  <>
                    <div className="float-right">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-sm"
                        onClick={() => LoadIps()}
                      >
                        <FaRedoAlt />
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-outline-success btn-sm"
                        onClick={() => {
                          handleIPInfoExportToCSV()
                          // window["exportTableToExcel"]("repo-data-usage-excel", "", "Repo-data-usage")
                        }}
                      >
                        {/* <strong>excel</strong> */}
                        <FaFileCsv />
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-outline-success btn-sm"
                        onClick={() => window["generatePDF"](props.type === 'Data Transfered by Percentage'? "project-top-10-ip2":"project-top-10-ip", "Top 10 IP")}
                      >
                        <FaFilePdf />
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </Col>
        </div>
        {iplist.length > 0 && (
          <>
            <div
              className="table-border"
              id="ip-data-usage-excel"
              style={{ height: "400px", overflow: "auto" }}
            >
              <Table id={props.type === 'Data Transfered by Percentage'? "project-top-10-ip2":"project-top-10-ip"}>
                <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                  <tr>
                    { props.type !== 'Data Transfered by Percentage' && <th></th> }
                    <th>IP</th>
                    <th>Country</th>
                    <th>Organization</th>
                    { props.type === 'Data Transfered by Percentage' && <th>Usage</th> }
                  </tr>
                </thead>
                <tbody>
                  {iplist.map((d, index) => {
                    return (
                      <tr key={index}>
                        { props.type !== 'Data Transfered by Percentage' && <td>
                          <MateriaUiTooltip title="View Repo Details">
                            <Button
                              outline
                              color="dark"
                              className="ml-1 float-right border-0 p-0"
                              id={`ip-${d.name}`}
                              onClick={() => {
                                setSelectPullData(d)
                                setClickedIP(d.name)
                                // setopenModal(true)
                                // showRepoModal({ gitRepoId: item.repoid })
                              }}
                            >
                              <FaRegEye />
                            </Button>
                          </MateriaUiTooltip>
                        </td>}
                        <td width="20%">{d.name}</td>
                        <td width="30%">{d.countryName}</td>
                        <td>{d.companyName}</td>
                        { props.type === 'Data Transfered by Percentage' && <th>{d.percentage}</th> }
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              <Modal
                isOpen={openModal}
                toggle={() => {
                  setopenModal(!openModal)
                }}
                backdrop="static"
                zIndex={1}
                size="xl"
                centered={true}
                className={classes.modal}
                style={{ width: "100%" }}
              >
                <ModalHeader
                  toggle={() => {
                    setopenModal(!openModal)
                    setSelectPullData(null)
                    setClickedIP(null)
                  }}
                >
                  Top 10 Git Repositories for IP : {clickedIP}
                </ModalHeader>
                <ModalBody>
                  {clickedIP !== null && (
                    <>
                      <RepoByIP
                        ip={clickedIP}
                        data={selectPullData}
                        startDate={props.startDate}
                        endDate={props.endDate}
                        parentOrganization={props.parentOrganization}
                        isSuperAdmin={props.isSuperAdmin}
                        projectSlug={props.projectSlug}
                        orgIndex={orgIndex}
                      />
                    </>
                  )}
                </ModalBody>
              </Modal>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default ProjectsByIP
