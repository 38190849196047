import React, { useState, useEffect } from "react"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/header/header"
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs"
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import Overview from "./detail/metrics-project-detail-overview"
import Users from "./detail/metrics-project-detail-users"
import ProjectsDataUsage from "./detail/metrics-project-detail-data-usage"
import Licenses from "./detail/metrics-project-detail-licenses"
import Mirrors from "./detail/metrics-project-detail-mirrors"
import Graph from "./detail/metrics-project-detail-graph"
import Git from "./detail/metrics-project-detail-git"
import Artifacts from "./detail/metrics-project-detail-artifacts"
import Wiki from "./detail/metrics-project-detail-wiki"
import { PropagateLoader } from "react-spinners"
import { fetch_get } from "../../../utils/fetch"
import "../../../styles/metrics.scss"
import { css } from "glamor"
import classnames from "classnames"
import { Link } from "gatsby"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import _ from "lodash"
import { useUserOrgs } from "../../../hooks/use-user"
import CIUsageIndex from "./detail/CIUsage/projectcontrols"

const PermissionDeniedPage = (
  <Layout pageTitle="Permission Denied">
    <Header contentPage={true} className="contentPage">
      <h1 className="display-4 font-weight-bold">Permission Denied</h1>
    </Header>
    <Content>
      <p>You have no permission to access this page.</p>
    </Content>
  </Layout>
)

export default function MetricsProjec(props) {
  const { getAccessTokenSilently } = useAuth0()
  const { orgIndex } = props
  const [loading, setLoading] = useState(true)
  const [projectInfo, setProjectInfo] = useState(false)
  const [roles, setRoles] = useState([])
  const [tabsName, setTabsName] = useState([
    { id: "Overview", value: "Overview" },
    { id: "Users", value: "Users" },
    { id: "Licenses", value: "Licenses" },
    { id: "Stats", value: "Stats" },
    { id: "Data Usage", value: "Data Usage" },
    // { id: "CI Usage", value: "CI Usage" }, // CI Usage Disabled for v1.9.0
  ])

  const queryParams = new URLSearchParams(window.location.search)
  const qtab = queryParams.get("t")
  var theDefaultTab = "Overview"
  if (qtab === "du") theDefaultTab = "Data Usage"
  else if (qtab === "cu") theDefaultTab = "CI Usage"
  else if (qtab === "stats") theDefaultTab = "Stats"
  else theDefaultTab = _.startCase(qtab) || "Overview"
  const [activeTab, setActiveTab] = useState(theDefaultTab)
  const [projectName, setProjectName] = useState("")
  const [noPermission, setNoPermission] = useState(false)

  const {
    currentOrg: {
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  useEffect(() => {
    if (props.parentOrganization === undefined || props.parentOrganization === null) return
    if (isSuperAdmin === undefined || isSuperAdmin === null) return

    var urlparams = ""
    if (isSuperAdmin) {
      urlparams = _.isNil(props.orgIndex)
        ? `?parentOrganization=${props.parentOrganization}`
        : `&parentOrganization=${props.parentOrganization}`
    }

    setLoading(true)
    setNoPermission(false)
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          (_.isNil(props.orgIndex)
            ? `projects/${props.projectId}`
            : `projects/${props.projectId}?index=${props.orgIndex}`) + urlparams,
          accessToken
        )
      )
      .then(response => {
        let newTabsName = [
          { id: "Overview", value: "Overview" },
          { id: "Users", value: "Users" },
          { id: "Licenses", value: "Licenses" },
        ]
        let projectRoles = ["admin", "controller"]
        setProjectInfo(response)
        setProjectName(response.name)

        if (!!response?.gitlabURL) {
          newTabsName.push({ id: "Git", value: "Git" })
          newTabsName.push({ id: "Mirrors", value: "Mirrors" })
          projectRoles = projectRoles.concat(["maintainer", "developer", "reporter"])
        }
        if (!!response?.artifactoryURL) {
          newTabsName.push({ id: "Artifacts", value: "Artifacts" })
        }
        if (!!response?.wikiURL) {
          newTabsName.push({ id: "Wiki", value: "Wiki" })
          projectRoles.push("editor")
        }
        newTabsName.push({ id: "Stats", value: "Stats" })
        newTabsName.push({ id: "Data Usage", value: "Data Usage" })
        // newTabsName.push({ id: "CI Usage", value: "CI Usage" }) // // CI Usage Disabled for v1.9.0
        setTabsName(newTabsName)
        setRoles(projectRoles)
        setLoading(false)
      })
      .catch(error => {
        if (error?.message === "Permission Denied") {
          setNoPermission(true)
        }
        setLoading(false)
        Sentry.captureException(error)
        console.log(`Error while getting user details: ${error}`)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentOrganization, props.orgIndex, isSuperAdmin])
  //props.currentProject.parentOrganization, isSuperAdmin

  const changeTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      if (tab === "Overview" || tab === "Licenses")
        window.history.pushState(
          "",
          "",
          window.location.origin + window.location.pathname + `?t=${tab.toLocaleLowerCase()}`
        )
    }
  }

  const navLink = tabsName => {
    return tabsName.map((item, index) => {
      return (
        <NavItem key={item.id}>
          <NavLink
            className={classnames({ active: activeTab === item.id })}
            onClick={() => {
              changeTab(item.id)
            }}
            key={item.id}
            {...css(styles.navLink)}
          >
            {item?.value}
          </NavLink>
        </NavItem>
      )
    })
  }

  function getCallbackUrl() {
    if (props.location.state?.from) return props.location.state?.from
    // if (!props.isSystemAdmin) return "/secure/workgroupadmin/"
    return `/metrics/${props.location.state?.query || ""}`
  }

  return noPermission ? (
    PermissionDeniedPage
  ) : (
    <Layout pageTitle={`${projectName} Metrics`}>
      {props.isSystemAdmin && (
        <Breadcrumbs
          breadcrumbs={[
            {
              label: "Metrics",
              path: "/metrics/",
            },
            { label: `${projectName} Metrics` },
          ]}
        />
      )}
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">
          <Link to={getCallbackUrl()}>
            <FaArrowAltCircleLeft className="goBackIcon" />
          </Link>
          {projectName} Metrics
        </h1>
        {projectInfo.markedForDeletion && (
          <>
            <small style={{ color: "red" }}>This project is marked for deletion</small>
          </>
        )}
      </Header>
      <Content>
        <div {...css(styles.box)}>
          {loading ? (
            <Col
              className="d-flex justify-content-center align-items-center"
              style={{ width: "100%", height: "100%", position: "absolute", zIndex: 99 }}
            >
              <PropagateLoader
                className="align-self-center"
                size={20}
                color={"#9c3"}
                loading={true}
              />
            </Col>
          ) : (
            <></>
          )}
          <div style={{ opacity: loading ? ".5" : "1" }}>
            <Nav tabs>{navLink(tabsName)}</Nav>
            <TabContent activeTab={activeTab} {...css(styles.tabContent)}>
              <TabPane tabId="Overview">
                {activeTab === "Overview" && (
                  <Row>
                    <Col sm="12">
                      <Overview {...projectInfo} queryParams={queryParams} />
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tabId="Users">
                {activeTab === "Users" && (
                  <Users
                    projectSlug={props.projectId}
                    parentOrganization={props.parentOrganization}
                    roles={roles}
                    queryParams={queryParams}
                  />
                )}
              </TabPane>
              <TabPane tabId="Licenses">
                {activeTab === "Licenses" && (
                  <Licenses licensesData={projectInfo.licenses || []} queryParams={queryParams} />
                )}
              </TabPane>
              <TabPane tabId="Git">
                {activeTab === "Git" && (
                  <Git
                    orgIndex={orgIndex}
                    parentOrganization={props.parentOrganization}
                    projectId={props.projectId}
                    queryParams={queryParams}
                  />
                )}
              </TabPane>
              <TabPane tabId="Mirrors">
                {activeTab === "Mirrors" && (
                  <Mirrors
                    orgIndex={orgIndex}
                    parentOrganization={props.parentOrganization}
                    projectId={props.projectId}
                    queryParams={queryParams}
                  />
                )}
              </TabPane>
              <TabPane tabId="Artifacts">
                {activeTab === "Artifacts" && (
                  <Artifacts
                    parentOrganization={props.parentOrganization}
                    orgIndex={orgIndex}
                    projectId={props.projectId}
                    queryParams={queryParams}
                  />
                )}
              </TabPane>
              <TabPane tabId="Wiki">
                {activeTab === "Wiki" && (
                  <Wiki
                    parentOrganization={props.parentOrganization}
                    orgIndex={orgIndex}
                    projectId={props.projectId}
                    queryParams={queryParams}
                  />
                )}
              </TabPane>
              <TabPane tabId="Stats">
                {!loading && activeTab === "Stats" && (
                  <Graph
                    orgIndex={orgIndex}
                    projectId={props.projectId}
                    hasArtifas={!!projectInfo?.artifactoryURL}
                    hasGit={!!projectInfo?.gitlabURL}
                    parentOrganization={props.parentOrganization}
                    isSuperAdmin={isSuperAdmin}
                    queryParams={queryParams}
                  />
                )}
              </TabPane>
              <TabPane tabId="Data Usage">
                {activeTab === "Data Usage" && (
                  <>
                    <ProjectsDataUsage
                      projectSlug={props.projectId}
                      parentOrganization={props.parentOrganization}
                      orgIndex={orgIndex}
                      queryParams={queryParams}
                    />
                  </>
                )}
              </TabPane>
              <TabPane tabId="CI Usage">
                {activeTab === "CI Usage" && (
                  <>
                    <CIUsageIndex
                      projectSlug={props.projectId}
                      parentOrg={props.parentOrganization}
                      orgIndex={orgIndex}
                      queryParams={queryParams}
                    />
                  </>
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Content>
    </Layout>
  )
}
const styles = {
  box: {
    position: "relative",
  },
  tabContent: {
    height: "100%",
    width: "100%",
    marginTop: "25px",
  },
  navLink: {
    cursor: "pointer",
  },
}
