import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Table,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { MdClose, MdPublic } from "react-icons/md"
import { fetch_get } from "../../../../utils/fetch"
import { PropagateLoader } from "react-spinners"
import { useAuth0 } from "@auth0/auth0-react"
import * as Sentry from "@sentry/gatsby"
import { css } from "glamor"
import "../../../../styles/metrics.scss"
import { Tooltip as MateriaUiTooltip, makeStyles } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { FaRegEye, FaSearch, FaRedoAlt } from "react-icons/fa"
import Overview from "./artifacts/file-detail-overview"
import Graph from "./artifacts/file-detail-graph"
import classnames from "classnames"
import { useUserOrgs } from "../../../../hooks/use-user"
import moment from "moment"

export default function MetricsProjectDetail(props) {
  const { orgIndex, queryParams } = props
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [projectArtifactsInfo, setProjectArtifactsInfo] = useState({})
  const [uniqueusers, setUniqueusers] = useState([])
  const [uniqueips, setUniqueips] = useState([])
  const [tableLoading, setTableLoading] = useState(true)
  const [tableError, setTableError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [tableDataCopy, setTableDataCopy] = useState([])
  const [fileModal, setFileModal] = useState(false)
  const [selectFile, setSelectFile] = useState({})
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(
    queryParams.get("page") ? parseInt(queryParams.get("page")) : 1
  )
  const [pageTotal, setPageTotal] = useState("")
  const [tabsName] = useState([
    { id: "1", value: "Overview" },
    { id: "2", value: " Stats" },
  ])
  const [artifactoryRepos, setArtifactoryRepos] = useState([])
  const [selectArtifactoryRepokey, setSelectArtifactoryRepokey] = useState(null)
  const [selectArtifactoryRepoID, setSelectArtifactoryRepoID] = useState(null)
  const {
    currentOrg: {
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const [searchText, setSearchText] = useState("")
  const [searchTextCopy, setSearchTextCopy] = useState("")
  const [searchTimer, setSearchTimer] = useState(null)

  const [activeTab, setActiveTab] = useState("1")

  const handleSearchValue = event => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    if (searchText === "" && searchTextCopy === "") return
    if (searchTimer !== null) {
      clearTimeout(searchTimer)
      setSearchTimer(null)
    }

    var k = setTimeout(() => {
      setCurrentPage(1)
      getArtifactFile(currentPage)
      setSearchTextCopy(searchText)
    }, 1500)
    setSearchTimer(k)
  }, [searchText])

  // useEffect(() => {
  //   var temp = JSON.parse(JSON.stringify(tableData))
  //   setTableDataCopy(temp)
  // }, [tableData])

  const changeTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const useStyles = makeStyles(() => ({
    modal: {
      width: 900,
    },
  }))
  const classes = useStyles()

  useEffect(() => {
    if (isSuperAdmin === undefined || isSuperAdmin === null) return
    get_project_artifactory_repos()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperAdmin])

  useEffect(() => {
    if (!selectArtifactoryRepoID) return
    if (!selectArtifactoryRepokey) return
    get_project_artifactory_detail()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectArtifactoryRepoID, selectArtifactoryRepokey])

  const get_project_artifactory_repos = () => {
    var urlparams = getAdditionalUrlParams()
    setLoading(true)
    setTableLoading(true)
    setArtifactoryRepos([])
    setSelectArtifactoryRepokey(null)
    setSelectArtifactoryRepoID(null)
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`projects/${props.projectId}/artifactory/repos${urlparams}&all=true`, accessToken)
      )
      .then(response => {
        const data = [...(response.data || [])].map(r => ({
          repoKey: r.artifactRepoID,
          repoName: r.repoName,
        }))
        setArtifactoryRepos(data)
        const node = queryParams.get("repoKey")
          ? data.find(d => d.repoName === queryParams.get("repoKey"))
          : null
        if (node) {
          setSelectArtifactoryRepoID(node.repoKey)
          setSelectArtifactoryRepokey(node.repoName)
        } else {
          setSelectArtifactoryRepoID(data[0] ? data[0].repoKey : null)
          setSelectArtifactoryRepokey(data[0] ? data[0].repoName : null)
        }
      })
      .catch(error => {
        setLoading(false)
        setTableLoading(false)
        Sentry.captureException(error)
        console.log(`Error while getting artifactory repos: ${error}`)
      })
  }

  const get_project_artifactory_detail = () => {
    var urlparams = getAdditionalUrlParams()
    const info = getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          `metrics/projects/${props.projectId}/artifactory${urlparams}&repoKey=${selectArtifactoryRepokey}`,
          accessToken
        )
      )
      .then(response => {
        setProjectArtifactsInfo(response.data)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
    const uniqueIps = getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          `metrics/projects/${props.projectId}/artifactory/topIps${urlparams}&repoKey=${selectArtifactoryRepokey}`,
          accessToken
        )
      )
      .then(response => {
        setUniqueips(response.map(item => item.ip))
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
    const uniqueUsers = getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          `metrics/projects/${props.projectId}/artifactory/uniqueusers${urlparams}&repoKey=${selectArtifactoryRepokey}`,
          accessToken
        )
      )
      .then(response => {
        setUniqueusers(response)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
    Promise.all([info, uniqueIps, uniqueUsers]).then(() => {
      setLoading(false)
    })
    setTableData([])
    setTableDataCopy([])
    getArtifactFile(queryParams.get("page") ? parseInt(queryParams.get("page")) : 1).catch(
      error => {
        setTableLoading(false)
        setTableError("An error occurred while getting the list of artifacts file.")
        console.log(`Error while getting workgroup artifacts file: ${error}`)
        Sentry.captureException(error)
      }
    )
  }

  const navLink = tabsName => {
    return tabsName.map((item, index) => {
      return (
        <NavItem key={item.id}>
          <NavLink
            className={classnames({ active: activeTab === item.id })}
            onClick={() => {
              changeTab(item.id)
            }}
            key={item.id}
            {...css(styles.navLink)}
          >
            {item?.value}
          </NavLink>
        </NavItem>
      )
    })
  }

  function getParentOrganization() {
    return props.parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (isSuperAdmin) {
      urlparams = `?parentOrganization=${getParentOrganization()}`
    } else {
      urlparams = `?index=${orgIndex}`
    }
    return urlparams
  }

  const getArtifactFile = currentPage => {
    var urlparams = getAdditionalUrlParams()
    urlparams = urlparams.replace("?", "&")

    let url = `projects/${props.projectId}/artifactory/${selectArtifactoryRepoID}/files?page=${currentPage}&limit=10${urlparams}&searchtext=${searchText}`
    setTableError(false)
    setTableLoading(true)
    window.history.pushState(
      "",
      "",
      window.location.origin +
        window.location.pathname +
        `?t=artifacts&page=${currentPage}&repoKey=${selectArtifactoryRepokey}`
    )
    return getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setTableData(response["data"] || [])
        setTableDataCopy(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
        setPageTotal(totalRecords)
        setTableLoading(false)
        setTableError(false)
      })
  }

  function onPageChange(event, page) {
    setCurrentPage(page)
    getArtifactFile(page).catch(error => {
      setTableLoading(false)
      setTableError("An error occurred while getting the list of artifacts file.")
      console.log(`Error while getting workgroup artifacts file: ${error}`)
      Sentry.captureException(error)
    })
  }

  function UseToolTip(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const { item, show } = props
    const text = item[show].length > 32 ? item[show].substring(0, 32) + "..." : item[show]
    return (
      <>
        <span id={`file-${show}-${item.gitRepoId}`}>
          {item[show].startsWith("https://") || item[show].startsWith("http://") ? (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <a
                className="linkJump handlePointer"
                {...css(styles.link)}
                onClick={() => {
                  window.open(`${item[show]}`, "_blank", "noreferrer")
                }}
              >
                {text}
              </a>
            </>
          ) : (
            text
          )}
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={`file-${show}-${item.gitRepoId}`}
          toggle={() => setTooltipOpen(!tooltipOpen)}
        >
          {item[show]}
        </Tooltip>
      </>
    )
  }

  function onChangeEvent(event) {
    var index = event.target.selectedIndex
    setSelectArtifactoryRepoID(event.target.value)
    setSelectArtifactoryRepokey(event.target[index].text)
  }

  const fileModalRender = () => {
    let urlparams = getAdditionalUrlParams()
    return (
      <>
        <Nav tabs>{navLink(tabsName)}</Nav>
        <TabContent
          activeTab={activeTab}
          {...css({
            height: "100%",
            width: "100%",
            marginTop: "25px",
          })}
        >
          <TabPane tabId="1">
            {activeTab === "1" && (
              <Overview
                urlparams={urlparams}
                selectFile={selectFile}
                repokey={selectArtifactoryRepoID}
                {...props}
              />
            )}
          </TabPane>
          <TabPane tabId="2">
            {activeTab === "2" && (
              <Graph
                urlparams={urlparams}
                selectFile={selectFile}
                repokey={selectArtifactoryRepokey}
                {...props}
              />
            )}
          </TabPane>
        </TabContent>
      </>
    )
  }

  const showFileModal = data => {
    setActiveTab("1")
    setSelectFile(data)
    setFileModal(!fileModal)
  }

  const ActionItem = data => {
    return (
      <>
        <MateriaUiTooltip title="View File Details">
          <Button
            outline
            color="dark"
            className="ml-1 float-right border-0 p-0"
            id={`FileView-${data.uri}`}
            onClick={() => showFileModal(data)}
          >
            <FaRegEye />
          </Button>
        </MateriaUiTooltip>
      </>
    )
  }

  return (
    <>
      <div style={{ display: "inline-flex", alignItems: "center", marginBottom: "1rem" }}>
        <div class="mr-4">Repository: </div>
        <div class="mr-4">
          <Input
            type="select"
            name={selectArtifactoryRepokey}
            id="repoKey"
            value={selectArtifactoryRepoID}
            onChange={event => onChangeEvent(event)}
          >
            {artifactoryRepos.map(p => (
              <option value={p.repoKey} name={p.repoName} key={p.repoName}>
                {p.repoName}
              </option>
            ))}
          </Input>
        </div>
      </div>
      {loading ? (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          sm={{ size: 4, offset: 4 }}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      ) : (
        <>
          <Row>
            <Col xs={{ size: 2 }} className="text-right">
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={() => get_project_artifactory_detail()}
              >
                <FaRedoAlt />
              </button>
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 2 }} className="text-right">
              Upload:
            </Col>
            <Col>{projectArtifactsInfo.upload || 0} times</Col>
          </Row>
          <Row>
            <Col xs={{ size: 2 }} className="text-right">
              Download:
            </Col>
            <Col>{projectArtifactsInfo.download || 0} times</Col>
          </Row>
          <Row>
            <Col xs={{ size: 2 }} className="text-right">
              File Count:
            </Col>
            <Col>{projectArtifactsInfo.filesCount || "-"}</Col>
          </Row>
          <Row>
            <Col xs={{ size: 2 }} className="text-right">
              Used Space:
            </Col>
            <Col>{projectArtifactsInfo.usedSpace || "0 GB"}</Col>
          </Row>
          <Row>
            <Col xs={{ size: 2 }} className="text-right">
              Unique Users:
            </Col>
            <Col>{uniqueusers.length ? uniqueusers.join(", ") : "-"}</Col>
          </Row>
          <Row>
            <Col xs={{ size: 2 }} className="text-right">
              Unique IPs:
            </Col>
            <Col>{uniqueips.length ? uniqueips.join(", ") : "-"}</Col>
          </Row>
        </>
      )}
      <div className="mt20">
        {tableLoading && (
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={{ size: 12 }}
            {...css(styles.loader)}
          >
            <PropagateLoader
              className="align-self-center"
              size={20}
              color={"#9c3"}
              loading={true}
            />
          </Col>
        )}
        <div style={{ opacity: tableLoading ? ".5" : "1" }}>
          {!tableLoading && tableError ? (
            <p {...css(styles.noData)}>{tableError}</p>
          ) : (
            <>
              <div className="float-left w-50">
                <InputGroup>
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm"
                    onClick={() => getArtifactFile(currentPage)}
                  >
                    <FaRedoAlt />
                  </button>
                  <Input
                    value={searchText}
                    placeholder="Search for a file..."
                    onChange={e => handleSearchValue(e)}
                    // onKeyPress={event => {
                    //   if (event.key === "Enter") {
                    //     event.preventDefault()
                    //     document.getElementById("searchButton").click()
                    //   }
                    // }}
                  />
                  {searchText !== "" && (
                    <InputGroupAddon className="clear_search" addonType="append">
                      <Button
                        onClick={() => {
                          setSearchText("")
                          // setSearch("")
                          // restartProjectList()
                          // emptySearchValue()
                        }}
                      >
                        <MdClose />
                      </Button>
                    </InputGroupAddon>
                  )}
                  <InputGroupAddon addonType="append">
                    <Button id="searchButton">
                      <FaSearch />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div className="float-right">
                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={onPageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
              <div className="float-right">
                <span {...css(styles.pageTotal)}>{`Total ${pageTotal} items`}</span>
                &nbsp;
              </div>

              {/* <div id="filesWrapper"> */}
              <div class="table-responsive">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th width="30%">Name</th>
                      {/* <th rowSpan={2}>Associated projects</th> */}
                      <th>Size</th>
                      <th>Modified</th>
                      {/* <th rowSpan={2}>Status</th>
                      <th rowSpan={2}>Created Date</th>
                      <th rowSpan={2}>Last Upload</th> */}
                      {/* <th
                        rowSpan={1}
                        colSpan={6}
                        style={{ textAlign: "center", borderBottom: "0px !important" }}
                      >
                        Metadata
                      </th> */}
                      <th>Action</th>
                    </tr>
                    {/* <tr>
                      <th rowSpan={1}>Package Name</th>
                      <th rowSpan={1}>License Identifier</th>
                      <th rowSpan={1}>Copyright</th>
                      <th rowSpan={1}>License</th>
                      <th rowSpan={1}>Package Version</th>
                      <th rowSpan={1}>Modification</th>
                    </tr> */}
                  </thead>
                  <tbody>
                    {tableDataCopy.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <a href={item.uri} target="_blank">
                              {item.name}
                            </a>
                          </td>
                          {/* <td>{item.projectName}</td> */}
                          <td>{item.size ? `${item.size} ` : ""}</td>
                          {/* <td>{item.status}</td>
                          <td>
                            {item.createdDate
                              ? moment(item.createdDate).format("YYYY-MM-DD HH:mm:ss")
                              : "-"}
                          </td>
                          <td>
                            {item.uploadDate
                              ? moment(item.uploadDate).format("YYYY-MM-DD HH:mm:ss")
                              : "-"}
                          </td>
                          <td>{item.metadata.packageName || "-"}</td>
                          <td>{item.metadata.licenseIdentifier || "-"}</td>
                          <td>{item.metadata.copyrightText || "-"}</td>
                          <td>{item.metadata.licenseText || "-"}</td>
                          <td>{item.metadata.packageVersion || "-"}</td>
                          <td>{item.metadata.modification + "" || "-"}</td> */}
                          <td>
                            {item.lastModified
                              ? moment(item.lastModified).format("YYYY-MM-DD HH:mm:ss")
                              : "-"}
                          </td>
                          <td>{ActionItem(item)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                {tableDataCopy.length <= 0 && (
                  <>
                    <p>There are no artifacts file.</p>
                  </>
                )}
              </div>

              <Modal
                isOpen={fileModal}
                toggle={() => setFileModal(!fileModal)}
                backdrop="static"
                zIndex={1500}
                size="xl"
                centered={true}
                className={classes.modal}
              >
                <ModalHeader toggle={() => setFileModal(!fileModal)}>File Detail</ModalHeader>
                <ModalBody>{fileModalRender()}</ModalBody>
              </Modal>
              {/* </div> */}
              <div className="float-right">
                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={onPageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
              <div className="float-right">
                <span {...css(styles.pageTotal)}>{`Total ${pageTotal} items`}</span>
                &nbsp;
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
const styles = {
  noData: {
    marginTop: "45px",
  },
  form: {
    maxHeight: "calc(100vh - 200px)",
    overflowY: "auto",
  },
}
