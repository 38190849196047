import React, { useState, useEffect } from "react"
import { Row, Col, Table, Button, Modal, ModalHeader, ModalBody } from "reactstrap"

import { fetch_get } from "../../../../utils/fetch"
import { PropagateLoader } from "react-spinners"
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Tooltip as MateriaUiTooltip,
} from "@material-ui/core"
import * as Sentry from "@sentry/gatsby"
import Pagination from "@material-ui/lab/Pagination"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import moment from "moment"
import { useUserOrgs } from "../../../../hooks/use-user"
import { getMonthYearList } from "../../../../utils/common"
import RepoDetail from "./git/repo-detail"
import { FaRegEye, FaRegFileExcel } from "react-icons/fa"
import FileSaver from "file-saver"
import ProjectsByIP from "./projectmetricsgraph/projectsByIP"

export default function ProjectsDataUsage(props) {
  var [openModal, setopenModal] = React.useState(false)
  const useStyles = makeStyles(() => ({
    modal: {
      width: 500,
    },
  }))
  const classes = useStyles()
  const { getAccessTokenSilently } = useAuth0()
  const [repoList, setRepoList] = useState([])
  const [gitPullTopIp, setGitPullTopIp] = useState([])
  const [totPayLoad, setTotPyaLoad] = useState(0)
  const [loadingData, setLoadingData] = useState(false)
  var monthsList = getMonthYearList()
  monthsList.sort((a, b) => (parseInt(a.key) < parseInt(b.key) ? 1 : -1))
  if (monthsList[0] !== undefined && monthsList[0] !== null)
    monthsList[0].value += ` | Current Month to date`
  if (monthsList[1] !== undefined && monthsList[1] !== null)
    monthsList[1].value += ` | Previous Month`
  const queryParams = new URLSearchParams(window.location.search)
  var qm = queryParams.get("m")
  if (qm !== undefined && qm !== null) qm = monthsList.find(n => n.key === qm).value
  else qm = monthsList[0].value
  const [selectedMonth, setSelectedMonth] = useState(props.queryParams.get("selectedMonth") || qm)
  const [resultMonth, setResultMonth] = useState(null)
  const [selectedRepo, setSelectedRepo] = useState(null)

  const [noUsers, setNoUsers] = useState("No repos found")
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const { orgIndex, projectSlug, roles } = props
  const [submitCounter, setSubmitcounter] = useState(0)
  const getUrl = `metrics/projects/${props.projectSlug}/git/bandwidth/upload`
  // _.isNil(orgIndex)
  //   ? `metrics/projects/${props.projectSlug}/git/bandwidth/upload`
  //   : `metrics/projects/${props.projectSlug}/git/bandwidth/upload?index=${orgIndex}`

  const {
    currentOrg: {
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  const handleMonthChange = event => {
    setSelectedMonth(event.target.value)
  }

  function get_repo_list() {
    setSubmitcounter(n => n + 1)
    var urlparams = ""

    setCurrentPage(1)
    let url = `${getUrl}/?`
    url = _.isNil(orgIndex) ? `${getUrl}/?` : `${getUrl}/?index=${orgIndex}&`
    url = filterCondition(url)

    if (isSuperAdmin) {
      urlparams = `&parentOrganization=${props.parentOrganization}`
    }
    url += urlparams
    let urlQuery = "?t=data-usage"
    if (selectedMonth) {
      var mm = getSelectedMonthProperties()
      // console.log(mm)
      urlQuery += `&selectedMonth=${mm.month}`
    }

    window.history.pushState("", "", window.location.origin + window.location.pathname + urlQuery)
    setLoadingData(true)
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setRepoList(response.repos || [])
        setResultMonth(selectedMonth)
        setTotPyaLoad(response.payload)
        // const totalRecords = response.repos.length || 0
        // const pageSize = response["pagination"]["pageSize"] || 10
        // setPageCount(Math.ceil(totalRecords / pageSize))
        setLoadingData(false)
        if (response.repos === undefined || response.repos.length === 0)
          setNoUsers("Sorry ! No repos found")
      })
      .catch(error => {
        console.log(`Error while getting list of repos: ${error}`)
        Sentry.captureException(error)
        setRepoList([])
        setNoUsers("An error occurred while getting the list of repos.")
        setLoadingData(false)
      })
  }

  const filterCondition = url => {
    if (selectedMonth !== undefined && selectedMonth !== null) {
      var month = monthsList.find(obj => obj.value === selectedMonth)
      // console.log(month)
      url += `startDate=${month.firstDay}&endDate=${month.lastDay}`
    }
    return url
  }

  const getSelectedMonthProperties = () => {
    var month = null
    if (selectedMonth !== undefined && selectedMonth !== null) {
      month = monthsList.find(obj => obj.value === selectedMonth)
    }
    return month
  }

  const getResultMonthObj = () => {
    return monthsList.find(n => n.value === resultMonth)
  }

  useEffect(() => {
    if (isSuperAdmin === undefined || isSuperAdmin === null) return
    if (selectedMonth === undefined || selectedMonth === null) return
    if (submitCounter === 0) get_repo_list()
  }, [selectedMonth, isSuperAdmin])

  useEffect(() => {
    if (selectedRepo === undefined || selectedRepo === null) return
    setopenModal(true)
  }, [selectedRepo])

  useEffect(() => {
    if (openModal) return
    if (selectedRepo === null) return
    setSelectedRepo(null)
  }, [openModal])

  // useEffect(() => {
  //   console.log(props, getMonthYearList())
  // }, [props])

  const handleExportToCSV = () => {
    const csvString = [
      ["Repo ID", "Repo Path", "Usage"],
      ...repoList.map(item => [`"${item.gitid}"`, `"${item.repopath}"`, `"${item.percentage}"`]),
    ]
      .map(e => e.join(","))
      .join("\n")
    let exportContent = "\uFEFF"
    let blob = new Blob([exportContent + csvString], {
      type: "text/plain;charset=utrf-8",
    })
    FileSaver.saveAs(blob, `project-repo-data-usage-${moment().format("yyyy/MM/DD")}.csv`)
  }

  return (
    <div className="d-flex flex-column" id="usersWrapper">
      <>
        <Row className="mb-2">
          <Col xs={{ size: 4 }}>
            <FormControl className="mb-2" style={{ width: "100%" }}>
              {/* <InputLabel>Month</InputLabel> */}
              <Select value={selectedMonth} onChange={handleMonthChange}>
                {monthsList.map((p, index) => (
                  <MenuItem key={p.key} value={p.value} style={{ height: 36 }}>
                    {p.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col xs={{ size: 1 }}>
            <FormControl className="mb-2" style={{ width: "100%" }}>
              {/* <button onClick={() => get_repo_list()}>submit</button> */}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => get_repo_list()}
              >
                <strong>Go</strong>
              </button>
            </FormControl>
          </Col>
          <Col>
            <div className="float-right">
              {!loadingData && repoList !== undefined && repoList != null && repoList.length > 0 && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={() => {
                      handleExportToCSV()
                      // window["exportTableToExcel"]("repo-data-usage-excel", "", "Repo-data-usage")
                    }}
                  >
                    {/* <strong>excel</strong> */}
                    <FaRegFileExcel />
                  </button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </>
      {loadingData ? (
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center pt-4"
            xs={{ size: 12 }}
            sm={{ size: 4, offset: 4 }}
          >
            <PropagateLoader
              className="align-self-center"
              size={20}
              color={"#9c3"}
              loading={true}
            />
          </Col>
        </Row>
      ) : (
        <>
          {/* <Row>
            <Col className="d-flex justify-content-center align-items-center mb-2">
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={onPageChange}
                variant="outlined"
                shape="rounded"
              />
            </Col>
          </Row> */}
          {/* <DisplayrepoList users={repoList} /> */}
          {repoList.length === 0 && submitCounter > 0 && (
            <>
              <Row>
                <Col xs={{ size: 12 }}>
                  <p>{noUsers}</p>
                </Col>
              </Row>
            </>
          )}
          {repoList.length > 0 && (
            <>
              <div
                className="table-border"
                id="repo-data-usage-excel"
                style={{ height: "400px", overflow: "auto" }}
              >
                <Table>
                  <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                    <tr>
                      <th></th>
                      <th width="10%">Repo Id</th>
                      <th width="55%">Repo Path</th>
                      <th width="10%">Total</th>
                      <th width="25%">Git / Bundle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {repoList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <MateriaUiTooltip title="View Repo Details">
                              <Button
                                outline
                                color="dark"
                                className="ml-1 float-right border-0 p-0"
                                id={`Repo-${item.gitid}`}
                                onClick={() => {
                                  setSelectedRepo(item)
                                  // setopenModal(true)
                                  // showRepoModal({ gitRepoId: item.repoid })
                                }}
                              >
                                <FaRegEye />
                              </Button>
                            </MateriaUiTooltip>
                          </td>

                          <td>{item.gitid}</td>
                          <td>
                            {/* <a href={item.repopath} target="_blank"> */}
                            {item.repopath}
                            {/* </a> */}
                          </td>
                          <td>{item.total_percentage}</td>
                          <td>
                            {item.git_percentage} / {item.bundle_percentage}
                          </td>
                          {/* <td width="10%">{((item.payload / totPayLoad) * 100).toFixed(3)} %</td> */}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="row">&nbsp;</div>
              <div>
                <ProjectsByIP
                  iplist={gitPullTopIp}
                  parentOrganization={props.parentOrganization}
                  isSuperAdmin={isSuperAdmin}
                  projectSlug={projectSlug}
                  orgIndex={orgIndex}
                  startDate={getResultMonthObj().firstDay}
                  endDate={getResultMonthObj().lastDay}
                  isProject={false}
                  type={"Data Transfered by Percentage"}
                />
              </div>

              <div className="row">&nbsp;</div>
              <div>
                <ProjectsByIP
                  iplist={gitPullTopIp}
                  parentOrganization={props.parentOrganization}
                  isSuperAdmin={isSuperAdmin}
                  projectSlug={projectSlug}
                  orgIndex={orgIndex}
                  startDate={getResultMonthObj().firstDay}
                  endDate={getResultMonthObj().lastDay}
                  isProject={false}
                  type={"Pull Requests"}
                />
              </div>
              <Modal
                isOpen={openModal}
                toggle={() => {
                  setopenModal(!openModal)
                }}
                backdrop="static"
                zIndex={1}
                size="xl"
                centered={true}
                className={classes.modal}
                style={{ width: "100%" }}
              >
                <ModalHeader toggle={() => setopenModal(!openModal)}>Repo Detail</ModalHeader>
                <ModalBody>
                  {selectedRepo !== null && (
                    <>
                      <RepoDetail
                        selectRepo={{ gitRepoId: selectedRepo.gitid }}
                        parentOrganization={props.parentOrganization}
                        projectId={selectedRepo.project_slug}
                        firstDay={getResultMonthObj().firstDay}
                        lastDay={getResultMonthObj().lastDay}
                      />
                    </>
                  )}
                </ModalBody>
              </Modal>
            </>
          )}
          {/* <Row>
            <Col className="d-flex justify-content-center align-items-center mt-2">
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={onPageChange}
                variant="outlined"
                shape="rounded"
              />
            </Col>
          </Row> */}
        </>
      )}
    </div>
  )
}
